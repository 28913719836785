import React, { useState } from 'react';
import styles from './addvault.module.css';
import withStudentAuth from '../../components/withStudentAuth';
import Header2 from '../../components/header2';
import { authData } from '../../components/getAuth';
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import * as moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from '../../../admin/components/Loader';
import { useNavigate } from 'react-router-dom';

const AddVault = () => {
    const auth = authData();
    const navigate = useNavigate();
    const [preview, setPreview] = useState(null);
    const [loading, setLoading] = useState(false);
    const { handleSubmit, register, formState: { errors }, control, setValue } = useForm();

    const onSubmit = (data) =>{
        setLoading(true);
        data = {...data,student_id: auth._id, date: moment(data?.date).format('DD/MM/YYYY')};
        axios.post(process.env.REACT_APP_API_URL + 'student/vault', data, {
            headers: { 'x-access-token': auth?.token }
        }).then(response => {
            setLoading(false);
            toast.success(response.data.message);
            setTimeout(()=>{
                navigate('/student/my-vault');
            },2000);
        }).catch(error => {
            setLoading(false);
            if (error && error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error("Error occured. Please try again");
            }
        });
    }
    
    const handleFileChange = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
          setPreview(null)
          return;
        }
        setLoading(true);
        const postData = new FormData();
        postData.append('file', e.target.files[0]);
        axios.post(process.env.REACT_APP_API_URL + 'public/upload-file', postData, {
            headers: { 'x-access-token': auth?.token }
        }).then(response => {
            setLoading(false);
            setPreview(response.data.data.url);
            setValue('image',response.data.data.name);
        }).catch(error => {
            setLoading(false);
            if (error && error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error("Error occured. Please try again");
            }
        });
    };
    
    return (<React.Fragment>
        <div className={`${styles.background_Color}`}>
            {loading && <Loader />}
            <Header2 />
            
            <div className={`${styles.Container}`}>
                <div className={`${styles.create_AccomplishmentSec}`}>
                    <p>Create Accomplishment</p>
                </div>
                <div className={`${styles.general_FormBoxArea}`}>
                    <div className={`${styles.general_TextAroSec}`}>
                        <img src="/static/img/rightsidearoicon.svg" alt="" />
                        <p className={`${styles.general_TextAroSecTitel}`}>General</p>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={`${styles.accomplishment_FormField}`}>
                            <label className={`${styles.accomplishment_Label}`}>Accomplishment</label>
                            <input type="text" placeholder="Name of Accomplishment" className={`${styles.accomplishment_inputTy}`} {...register("accomplishment",{ required: 'This field is required' })} />
                            {errors?.accomplishment && <p className={`${styles.login_ErrorM}`}>{errors?.accomplishment?.message}</p>}
                        </div>
                        <div className={`${styles.accomplishment_FormField}`}>
                            <label className={`${styles.accomplishment_Label}`}>Competition</label>
                            <input type="text" placeholder="Name of Competition" className={`${styles.accomplishment_inputTy}`} {...register("competition",{ required: 'This field is required' })} />
                            {errors?.competition && <p className={`${styles.login_ErrorM}`}>{errors?.competition?.message}</p>}
                        </div>
                        <div className={`${styles.accomplishment_FormField}`}>
                            <label className={`${styles.accomplishment_Label}`}>Description</label>
                            <textarea placeholder="Description" className={`${styles.FormTextInputArea}`} {...register("description",{ required: 'This field is required' })}></textarea>
                            {errors?.description && <p className={`${styles.login_ErrorM}`}>{errors?.description?.message}</p>}
                        </div>
                        <div className={`${styles.accomplishment_FormRow}`}>
                            <div className={`${styles.accomplishment_FormRowHalf}`}>
                                <div className={`${styles.accomplishment_FormField}`}>
                                    <label className={`${styles.accomplishment_Label}`}>Class</label>
                                    <input type="number" placeholder="Name of Class" className={`${styles.accomplishment_inputTy}`} {...register("class",{ required: 'This field is required' })} />
                                    {errors?.class && <p className={`${styles.login_ErrorM}`}>{errors?.class?.message}</p>}
                                </div>
                            </div>
                            <div className={`${styles.accomplishment_FormRowHalf}`}>
                                <div className={`${styles.accomplishment_FormField}`}>
                                    <label className={`${styles.accomplishment_Label}`}>Date</label>
                                    <Controller control={control} {...register('date', { required: 'This field is required' })} render={({ field: { ref, ...field } }) => (
                                        <DatePicker {...field} inputRef={ref} selected={field.value} placeholderText={'Date Of Accomplishment'} dateFormat="dd/MM/yyyy" autoComplete='off' className={`${styles.accomplishment_inputTy}`} />
                                    )} />
                                    {errors?.date && <p className={`${styles.login_ErrorM}`}>{errors?.date?.message}</p>}
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.chackBox_BUSec}`}>
                            <div className={`${styles.Tex_InputChackGrop}`}>
                                <p className={`${styles.chackBox_Text}`}>Image</p>
                            </div>
                            <input style={{'display':'none'}} id="file" type="file" name="file" accept="image/*" onChange={handleFileChange} />
                            <label htmlFor="file" className={`${styles.chackBoxButton}`}>Add Image</label>
                            {preview &&  <img src={preview} style={{'maxWidth':'300px','maxHeight':'150px'}} alt='' /> }
                        </div>
                        <div className={`${styles.ending_SaveSec}`}>
                            <button type='submit' className={`${styles.ending_SaveBU}`}>Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </React.Fragment>);
}

export default withStudentAuth(AddVault);