import React from 'react';
import styles from './faq.module.css';
import Header2 from '../../components/header2';
import { authData } from '../../components/getAuth';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import { toast } from 'react-smart-toaster';
import Header1 from '../../components/header1';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ = () => {
    const auth = authData();
    const [faqList, setFaqList] = useState([]);
    const [expanded, setExpanded] = useState(false);
  
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + 'public/faq-list', {
            headers: { 'x-access-token': auth?.token }
        }).then(response => {
            setFaqList(response.data.data);
        }).catch(error => {
            setFaqList([]);
            if (error && error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error("Error occured. Please try again");
            }
        });
    }, [auth?.token]);

    return (<React.Fragment>
        {auth && <Header2 />}
        {!auth && <Header1 />}

        <div className={`${styles.Container}`}>
        <div className={`${styles.my_DashboardMainArea}`}>
          <div className={`${styles.my_DashboardNam}`}>
            <p className={`${styles.my_DashboarHeading}`}>FAQ List</p>
          </div>
        </div>
      </div>

      <div className={`${styles.Container}`}>
        <div className={`${styles.Row}`}>
          <div className={`${styles.BigDiv_Sec}`}>

            <div className={`${styles.Row}`}>
              {faqList.map((item,index)=>{
                return <Accordion key={item._id}  expanded={expanded === 'panel'+index} onChange={handleChange('panel'+index)}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id={"panel"+index+"-header"}>{item?.title}</AccordionSummary>
                    <AccordionDetails>
                        <div style={{whiteSpace: "pre-wrap"}}>{item?.description}</div>
                    </AccordionDetails>
                </Accordion>})}

              {(faqList.length === 0) && <div style={{width:'100%', margin: '14px 8px 0'}}>
                <div className={`${styles.noData_FoundSec}`} style={{borderRadius:'8px'}}>
                  <div className={`${styles.noData_Found_SecUnder}`}>
                    <img src='/static/img/no-datafound.png' alt='' />
                    <p>No FAQ are available now</p>
                  </div>
                </div>
              </div>}

            </div>
          </div>
        </div>
      </div>

    </React.Fragment>);
}

export default FAQ;