import React from 'react';
import styles from './grouplist.module.css';
import { Link } from 'react-router-dom';
import Header2 from '../../components/header2';
import withStudentAuth from '../../components/withStudentAuth';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import { toast } from 'react-smart-toaster';

const Dashboard = () => {
  const [groupList, setGroupList] = useState([]);

  useEffect(()=>{
        axios.get(process.env.REACT_APP_API_URL + 'public/group-list').then(response => {
          setGroupList(response.data.data);
        }).catch(error => {
            if(error && error.response && error.response.data && error.response.data.message){
                toast.error(error.response.data.message);
            }else{
                toast.error("Error occured. Please try again");
            }
        });
      },[]);

    return (<React.Fragment>
        <Header2 />



        <div className={`${styles.Container}`}>
            <div className={`${styles.viewAll_Sec}`}>
                <p className={`${styles.viewAll_LeftSideText}`}>Group List</p>
            </div>
        </div>

        <div className={`${styles.Container}`}>
            <div className={`${styles.Row}`}>
                <div className={`${styles.Explore_EndingHalf}`}>
                    <div className={`${styles.Explore_EndingCusRow}`}>
                    {groupList?.map((item,index)=>{
                        let className = `${styles.Explore_EndingMainCont}`;
                        if((index%4) === 1)
                            className = `${styles.Explore_EndingMainCont} ${styles.yelloColr__Pro}`;
                        if((index%4) === 2)
                            className = `${styles.Explore_EndingMainCont} ${styles.purpleColr__Pro}`;
                        if((index%4) === 3)
                            className = `${styles.Explore_EndingMainCont} ${styles.greenColr__Pro}`;
                        return <div className={`${styles.Explore_EndingFeild}`}>
                        <div className={className}>
                            <p className={`${styles.classText}`}>{item?.classes}</p>
                            <p className={`${styles.subjectText}`}>{item?.title}</p>
                            <p className={`${styles.subjectMiniText}`}>{item?.description}</p>
                            <Link to={"/student/group-test-list/"+item._id} className={`${styles.Explore_EndingButton}`}>Explore</Link>
                        </div>
                        </div>
                        })}
                    </div>
                </div>
                
            </div>
        </div>
    </React.Fragment>);
}

export default withStudentAuth(Dashboard);