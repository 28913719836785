import React, { useEffect, useState } from 'react';
import styles from './home.module.css';
import { Link } from 'react-router-dom';
import Header1 from '../../components/header1';
import Header2 from '../../components/header2';
import { authData } from '../../components/getAuth';
import ClassArea from '../../components/classArea';
import axios from 'axios';
import { toast } from 'react-smart-toaster';

const Home = () => {
  const auth = authData();

  const [materialList, setMaterialList] = useState([]);
  const [groupList, setGroupList] = useState([]);

  useEffect(()=>{
    axios.get(process.env.REACT_APP_API_URL + 'public/material-list').then(response => {
      setMaterialList(response.data.data.splice(0,4));
    }).catch(error => {
        if(error && error.response && error.response.data && error.response.data.message){
            toast.error(error.response.data.message);
        }else{
            toast.error("Error occured. Please try again");
        }
    });
},[]);

useEffect(()=>{
  axios.get(process.env.REACT_APP_API_URL + 'public/group-list').then(response => {
    setGroupList(response.data.data.splice(0,4));
  }).catch(error => {
      if(error && error.response && error.response.data && error.response.data.message){
          toast.error(error.response.data.message);
      }else{
          toast.error("Error occured. Please try again");
      }
  });
},[]);

const openFileUrl = (item) =>{
  window.open(item?.file_url);
}
  
  return (<React.Fragment>
    {auth ? <Header2 />:<Header1 />}
    
    <div className={`${styles.Container}`}>
      <div className={`${styles.Explor_TestsMainArea}`}>
        <div className={`${styles.Explor_TestsImgSec}`}>
          <img src="/static/img/Frame1.svg" alt="" />
        </div>
        <div className={`${styles.Explor_TestsTextSec}`}>
          <p className={`${styles.Explor_TestsTitelBig}`}>Take a Quiz -Challenge yourself to Win the</p>
          <p className={`${styles.Explor_TestsTitelBig1}`}>Knowledge treasure</p>
          <p className={`${styles.Explor_TestsTitelSmall}`}>Explore Topic specific tests </p>
          <Link to={'/student/explore-tests'} className={`${styles.Explor_TestsLoginBU}`}>Explore tests</Link>
        </div>
      </div>
    </div>
    
    <div className={`${styles.Container}`}>
      <div className={`${styles.CreateA_QuizMainArea}`}>
        <div className={`${styles.CreateA_QuizTextSec}`}>
          <p className={`${styles.CreateA_QuizTitel}`}>Create And a Quiz<br/>and invite your Friends to Join</p>
          <div className={`${styles.CreateA_QuizButton}`}>
            <Link to={'/student/test-list'} className={`${styles.CreateA_QuizLoginBU}`}>Join a Quiz</Link>
          </div>
        </div>
        <div className={`${styles.CreateA_QuizImgSec}`}>
          <img src="/static/img/Frame2.png" alt="" />
        </div>
      </div>
    </div>

    <ClassArea />

    <div className={`${styles.Container}`}>
      <div className={`${styles.viewAll_Sec}`}>
        <p className={`${styles.viewAll_LeftSideText}`}>Study Material</p>
        <div className={`${styles.disflex}`}>
          <Link to={'/material-list'}><p className={`${styles.viewAll_RightSideText}`}>View all</p></Link>
          <Link to={'/material-list'}><i data-feather="chevron-right"></i></Link>
        </div>
      </div>
    </div>
    
    <div className={`${styles.Container}`}>
      <div className={`${styles.Row}`}>
        {materialList?.map(item =>{
          return <div className={`${styles.SolutionsMainArae}`} key={item?._id}>
            <div className={`${styles.SolutionsCont}`} onClick={() => openFileUrl(item)}>
              <div>
                <p className={`${styles.SolutionsTextBig}`}>{item?.class?.title}</p>
                <p className={`${styles.SolutionsTextsmall}`}>{item?.title}</p>
              </div>
            </div>
          </div>
        })}
      </div>
    </div>
    
    <div className={`${styles.Container}`}>
      <div className={`${styles.viewAll_Sec}`}>
        <p className={`${styles.viewAll_LeftSideText}`}>Groups</p>
        <div className={`${styles.disflex}`}>
          <Link to={'/home'}><p className={`${styles.viewAll_RightSideText}`}>View all</p></Link>
          <Link to={'/home'}><i data-feather="chevron-right"></i></Link>
        </div>
      </div>
    </div>
    
    <div className={`${styles.Container}`}>
      <div className={`${styles.Row}`}>
        <div className={`${styles.Explore_EndingHalf}`}>
          <div className={`${styles.Explore_EndingCusRow}`}>
            {groupList?.map((item,index)=>{
              let className = `${styles.Explore_EndingMainCont}`;
              if(index === 1)
                className = `${styles.Explore_EndingMainCont} ${styles.yelloColr__Pro}`;
              if(index === 2)
                className = `${styles.Explore_EndingMainCont} ${styles.purpleColr__Pro}`;
              if(index === 3)
                className = `${styles.Explore_EndingMainCont} ${styles.greenColr__Pro}`;
              return <div className={`${styles.Explore_EndingFeild}`}>
              <div className={className}>
                <p className={`${styles.classText}`}>{item?.classes}</p>
                <p className={`${styles.subjectText}`}>{item?.title}</p>
                <p className={`${styles.subjectMiniText}`}>{item?.description}</p>
                <Link to={"/student/group-test-list/"+item._id} className={`${styles.Explore_EndingButton}`}>Explore</Link>
              </div>
            </div>
            })}
          </div>
        </div>
        <div className={`${styles.Explore_EndingHalf}`}>
          <div className={`${styles.Explore_EndingHalfMainCont}`}>
            <p className={`${styles.Explore_EndingHalfMainContText}`}>Explore Groups where you<br/>can find your dream class</p>
            <img src="/static/img/Frame1.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>);
}

export default Home;