import React, { useState } from 'react';
import styles from './changepassword.module.css';
import Header2 from '../../components/header2';
import withMentorAuth from '../../components/withMentorAuth';
import { useNavigate } from 'react-router-dom';
import { authData } from '../../components/getAuth';
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import { useForm } from 'react-hook-form';

const ChangePassword = () => {
  const auth = authData();
  const navigate = useNavigate();
  const [validationError, setValidationError] = useState('');

  const { handleSubmit, register, formState: { errors }, watch } = useForm();

  const onSubmit = (data) =>{
    setValidationError('');
    axios.put(process.env.REACT_APP_API_URL + 'mentor/change-password', data, {
      headers: { 'x-access-token': auth?.token }
    }).then(response => {
      navigate('/mentor/my-profile');
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        if(error.response?.data?.type === 'validation'){
          setValidationError(error.response.data.message)
        }else{
          toast.error(error.response.data.message);
        }
      } else {
        toast.error("Error occured. Please try again");
      }
    });
  }

return (<React.Fragment>
    <div className={`${styles.background_Color}`}>
      <Header2 />

      <div className={`${styles.Container}`}>
        <div className={`${styles.create_AccomplishmentSec}`}>
          <p>Change Password</p>
        </div>
        <div className={`${styles.general_FormBoxArea}`}>
          <div className={`${styles.general_TextAroSec}`}>
            <p className={`${styles.general_TextAroSecTitel}`}>General</p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
          <div className={`${styles.accomplishment_FormField}`}>
              <label className={`${styles.accomplishment_Label}`}>Old Password*</label>
              <input type="password" placeholder="Old Password" className={`${styles.accomplishment_inputTy}`} {...register("old_password",{ required: 'This field is required' })} />
              {errors?.old_password && <p className={`${styles.login_ErrorM}`}>{errors?.old_password?.message}</p>}
              {(validationError !== '') && <p className={`${styles.login_ErrorM}`}>{validationError}</p>}
            </div>
            <div className={`${styles.accomplishment_FormField}`}>
              <label className={`${styles.accomplishment_Label}`}>New Password*</label>
              <input type="password" placeholder="New Password" className={`${styles.accomplishment_inputTy}`} {...register("new_password",{ 
                required: 'This field is required',
                minLength: {
                  value: 6,
                  message: "Please enter atleast 6 characters"
                }
              })} />
              {errors?.new_password && <p className={`${styles.login_ErrorM}`}>{errors?.new_password?.message}</p>}
            </div>
            <div className={`${styles.accomplishment_FormField}`}>
              <label className={`${styles.accomplishment_Label}`}>Confirm New Password*</label>
              <input type="password" placeholder="Confirm New Password" className={`${styles.accomplishment_inputTy}`} {...register("conf_new_password",{ 
                required: 'This field is required',
                validate: (value) => value === watch('new_password') || "The passwords do not match"
              })} />
              {errors?.conf_new_password && <p className={`${styles.login_ErrorM}`}>{errors?.conf_new_password?.message}</p>}
            </div>
            <div className={`${styles.ending_SaveSec}`}>
              <button type='submit' className={`${styles.ending_SaveBU}`}>Update</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </React.Fragment>)
}

export default withMentorAuth(ChangePassword);