import React, { useEffect, useState } from 'react';
import styles from './testlist.module.css';
import withStudentAuth from '../../components/withStudentAuth';
import Header2 from '../../components/header2';
import Select from "react-select";
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import { authData } from '../../components/getAuth';
import * as moment from 'moment';
import { useNavigate } from 'react-router-dom';
import {Typography,Skeleton,Stack,Pagination} from '@mui/material';

const TestList = () => {
  const auth = authData();
  const navigate = useNavigate();
  const perPage = 10;
  
  const [boardDDList, setBoardDDList] = useState([]);
  const [defaultBoard, setDefaultBoard] = useState(null);
  const [classDDList, setClassDDList] = useState([]);
  const [defaultClass, setDefaultClass] = useState(null);
  const [subjectDDAllList, setSubjectDDAllList] = useState([]);
  const [subjectDDList, setSubjectDDList] = useState([]);
  const [defaultSubject, setDefaultSubject] = useState(null);
  const [topicDDList, setTopicDDList] = useState([]);
  const [defaultTopic, setDefaultTopic] = useState(null);
  const [topicList, setTopicList] = useState([]);
  const [searchParams, setSearchParams] = useState({});
  const [testList, setTestList] = useState([]);
  const [contentLoading, setContentLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  
  useEffect(()=>{
    function getTestListTemp(sParams){
      setContentLoading(true);
      let params = {};
      if(sParams?.board_id){
        params = {...params, board_id: sParams?.board_id};
      }
      if(sParams?.class_id){
        params = {...params, class_id: sParams?.class_id};
      }
      if(sParams?.subject_id){
        params = {...params, subject_id: sParams?.subject_id};
      }
      if(sParams?.topic_id){
        if(sParams?.topic_id !== 'all')
          params = {...params, topic_id: sParams?.topic_id};
      }
      if(sParams?.level){
        params = {...params, level: sParams?.level};
      }
      params = {...params, perPage: perPage, page:1};
      axios.get(process.env.REACT_APP_API_URL + 'student/test-list', {
        params,
        paramsSerializer: function paramsSerializer(params) {
          return Object.entries(Object.assign({},params)).map(([key, value]) => `${key}=${value}`).join('&');
        },
        headers: { 'x-access-token': auth?.token }
      }).then(response => {
        setContentLoading(false);
        setTestList(response.data.data);

        let pageCount = response.data.totalTest/perPage;
        if(pageCount > parseInt(pageCount))
          pageCount = parseInt(pageCount)+1;

        setPageCount(pageCount);
      }).catch(error => {
        setContentLoading(false);
        setTestList([]);
        if(error && error.response && error.response.data && error.response.data.message){
          toast.error(error.response.data.message);
        }else{
          toast.error("Error occured. Please try again");
        }
      });
    }
    const testFilterData = localStorage.getItem("testFilterData");
    if(testFilterData){
      getTestListTemp(JSON.parse(testFilterData));
      setSearchParams(JSON.parse(testFilterData));
    }else{
      let testFilterDataArr={};
      if(auth?.student_info?.board_id){
        testFilterDataArr = {...testFilterDataArr,board_id: auth?.student_info?.board_id};
      }
      if(auth?.student_info?.class_id){
        testFilterDataArr = {...testFilterDataArr,class_id: auth?.student_info?.class_id};
      }
      getTestListTemp(testFilterDataArr);
      setSearchParams(testFilterDataArr);
    }
  },[auth?.student_info?.board_id,auth?.student_info?.class_id,auth?.token]);
  
  
  
  useEffect(()=>{
    axios.get(process.env.REACT_APP_API_URL + 'public/boards').then(response => {
      let boardListTemp = response.data.data.map(i=> { return { value:i._id, label:i.title }; });
      setBoardDDList(boardListTemp);
      const found = boardListTemp.find(i => i.value === searchParams?.board_id);
      if(found){
        setDefaultBoard(found);
      }else{
        setDefaultBoard(boardListTemp[0]);
        setSearchParams(prev=>{
          return {...prev,board_id: boardListTemp[0]?.value};
        })
      }
    }).catch(error => {
      if(error && error.response && error.response.data && error.response.data.message){
        toast.error(error.response.data.message);
      }else{
        toast.error("Error occured. Please try again");
      }
    });
  },[searchParams]);
  
  useEffect(()=>{
    axios.get(process.env.REACT_APP_API_URL + 'public/classes').then(response => {
      let classListTemp = response.data.data.map(i=> { return { value:i._id, label:i.title }; });
      setClassDDList(classListTemp);
      if(searchParams?.class_id){
        const found = classListTemp.find(i => i.value === searchParams?.class_id);
        setDefaultClass(found);
      }else{
        setDefaultClass(null);
      }
    }).catch(error => {
      if(error && error.response && error.response.data && error.response.data.message){
        toast.error(error.response.data.message);
      }else{
        toast.error("Error occured. Please try again");
      }
    });
  },[searchParams]);
  
  useEffect(()=>{
    console.log(searchParams);
    axios.get(process.env.REACT_APP_API_URL + 'public/subjects').then(response => {
      let subjectListTemp = response.data.data;
      setSubjectDDAllList(subjectListTemp);
      if(searchParams?.subject_id){
        const found = subjectListTemp.map(i=> { return { value:i._id, label:i.title }; }).find(i => i.value === searchParams?.subject_id);
        setDefaultSubject(found);
      }else{
        setDefaultSubject(null);
      }
    }).catch(error => {
      if(error && error.response && error.response.data && error.response.data.message){
        toast.error(error.response.data.message);
      }else{
        toast.error("Error occured. Please try again");
      }
    });
  },[searchParams]);
  
  useEffect(()=>{
    axios.get(process.env.REACT_APP_API_URL + 'public/topics').then(response => {
      setTopicList(response.data.data);
    }).catch(error => {
      if(error && error.response && error.response.data && error.response.data.message){
        toast.error(error.response.data.message);
      }else{
        toast.error("Error occured. Please try again");
      }
    });
  },[]);
  
  useEffect(()=>{
    if(searchParams?.class_id){
      let subjectListTemp = subjectDDAllList.filter(i => (i.classes.indexOf(searchParams?.class_id) > -1) && (i.boards.indexOf(searchParams?.board_id) > -1)).map(i => { return { value: i._id, label: i.title }; });
      setSubjectDDList(subjectListTemp);
    }
  },[searchParams?.board_id,searchParams?.class_id,subjectDDAllList]);
  
  useEffect(()=>{
    if(searchParams?.board_id && searchParams?.class_id && searchParams?.subject_id){
      let topicListTemp = topicList.filter(i=> (i.board_id === searchParams.board_id && i.class_id === searchParams.class_id && i.subject_id === searchParams.subject_id)).map(i=>{
        return { value:i._id, label:i.title };
      });
      topicListTemp = [{value:'all',label:'All Chapter'}].concat(topicListTemp);
      setTopicDDList(topicListTemp);
      const found = topicListTemp.find(i => i.value === searchParams?.topic_id);
      setDefaultTopic(found);
    }else{
      setDefaultTopic(null);
    }
  },[searchParams,topicList]);
  
  const cngBoard = (e)=>{
    let testFilterDataArr=searchParams;
    if(searchParams?.board_id !== e.value){
      testFilterDataArr = {...testFilterDataArr,board_id: e.value, class_id: null, subject_id: null, topic_id: null };
      setSearchParams(testFilterDataArr);
      setDefaultClass(null);
      setDefaultSubject(null);
      setDefaultTopic(null);
    }
  }
  
  const cngClass = (e)=>{
    let testFilterDataArr=searchParams;
    if(searchParams?.class_id !== e.value){
      testFilterDataArr = {...testFilterDataArr,class_id: e.value, subject_id: null, topic_id: null };
      setSearchParams(testFilterDataArr);
      setDefaultSubject(null);
      setDefaultTopic(null);
    }
  }
  
  const cngSubject = (e)=>{
    let testFilterDataArr=searchParams;
    if(searchParams?.subject_id !== e.value){
      testFilterDataArr = {...testFilterDataArr,subject_id: e.value, topic_id: null };
      setSearchParams(testFilterDataArr);
      setDefaultTopic(null);
    }
  }
  
  const cngTopic = (e)=>{
    let testFilterDataArr=searchParams;
    if(searchParams?.topic_id !== e.value){
      testFilterDataArr = {...testFilterDataArr,topic_id: e.value };
      setSearchParams(testFilterDataArr);
    }
  }
  
  const cngLevel = (e)=>{
    let testFilterDataArr=searchParams;
    if(searchParams?.level !== e.value){
      testFilterDataArr = {...testFilterDataArr,level: e.value };
      setSearchParams(testFilterDataArr);
    }
  }

  useEffect(()=>{
    const getTestList = (sParams) =>{
      setContentLoading(true);
      let params = {};
      if(sParams?.board_id){
        params = {...params, board_id: sParams?.board_id};
      }
      if(sParams?.class_id){
        params = {...params, class_id: sParams?.class_id};
      }
      if(sParams?.subject_id){
        params = {...params, subject_id: sParams?.subject_id};
      }
      if(sParams?.topic_id){
        if(sParams?.topic_id !== 'all')
          params = {...params, topic_id: sParams?.topic_id};
      }
      if(sParams?.level){
        params = {...params, level: sParams?.level};
      }
      params = {...params, perPage: perPage, page:page};
      axios.get(process.env.REACT_APP_API_URL + 'student/test-list', {
        params,
        paramsSerializer: function paramsSerializer(params) {
          return Object.entries(Object.assign({},params)).map(([key, value]) => `${key}=${value}`).join('&');
        },
        headers: { 'x-access-token': auth?.token }
      }).then(response => {
        setContentLoading(false);
        setTestList(response.data.data);
  
        let pageCount = response.data.totalTest/perPage;
        if(pageCount > parseInt(pageCount))
          pageCount = parseInt(pageCount)+1;
  
        setPageCount(pageCount);
    }).catch(error => {
        setContentLoading(false);
        setTestList([]);
        if(error && error.response && error.response.data && error.response.data.message){
          toast.error(error.response.data.message);
        }else{
          toast.error("Error occured. Please try again");
        }
      });
    }

    getTestList(searchParams);
  },[page,searchParams,auth?.token]);
  
  const JoinQuiz = (item) =>{
    axios.get(process.env.REACT_APP_API_URL + 'student/check-quiz/'+item._id, {
      headers: { 'x-access-token': auth?.token }
    }).then(response => {
      if(item.is_restrict){
        navigate('/student/join-quiz/'+item?._id);
      }else{
        navigate('/student/quiz/'+item?._id);
      }
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
      } else {
          toast.error("Error occured. Please try again");
      }
    });
  }
  
  return (<React.Fragment>
    <Header2 />
    <div className={`${styles.Container}`}>
      <div className={`${styles.main_DivSec}`}>
        <div className={`${styles.mainDivSec_SmallSide}`}>
          <div className={`${styles.mainDivSec_SmallSideCard}`}>
            <div className={`${styles.mainDivSec_SmallSideTitleArea}`}>
              <p className={`${styles.mainDivSec_SmallSideTitle}`}>FILTER</p>
              {/*<p className={`${styles.mainDivSec_SmallSideTitleRedColr}`}>CLEAR ALL</p>*/}
            </div>
            <div className={`${styles.mainDivSec_ButtomFieldHalf}`}>
              <span className={`${styles.details}`}>Board</span>
              <Select options={boardDDList} placeholder={'Board'} value={defaultBoard}  onChange={cngBoard} />
            </div>
            <div className={`${styles.mainDivSec_ButtomFieldHalf}`}>
              <span className={`${styles.details}`}>Class</span>
              <Select options={classDDList} placeholder={'Class'} value={defaultClass}  onChange={cngClass} />
            </div>
            <div className={`${styles.mainDivSec_ButtomFieldHalf}`}>
              <span className={`${styles.details}`}>Subject</span>
              <Select options={subjectDDList} placeholder={'Subject'} value={defaultSubject}  onChange={cngSubject} />
            </div>
            <div className={`${styles.mainDivSec_ButtomFieldHalf}`}>
              <span className={`${styles.details}`}>Topic</span>
              <Select options={topicDDList} placeholder={'Topic'} value={defaultTopic} onChange={cngTopic} />
            </div>
            <div className={`${styles.mainDivSec_ButtomFieldHalf}`}>
              <span className={`${styles.details}`}>Level</span>
              <Select options={[{ label: 'Easy', value: 'Easy' }, { label: 'Medium', value: 'Medium' }, { label: 'Hard', value: 'Hard' }]} placeholder={'Level'}  onChange={cngLevel} />
            </div>
          </div>
        </div>
        <div className={`${styles.mainDivSec_BigSide}`}>
          {contentLoading && <>
          <Typography component="div" key={'h2'} variant={'h2'}>
            <Skeleton />
          </Typography>
          <Typography component="div" key={'h2'} variant={'h2'}>
            <Skeleton />
          </Typography>
          <Typography component="div" key={'h2'} variant={'h2'}>
            <Skeleton />
          </Typography>
          <Typography component="div" key={'h2'} variant={'h2'}>
            <Skeleton />
          </Typography>
          <Typography component="div" key={'h2'} variant={'h2'}>
            <Skeleton />
          </Typography>
          <Typography component="div" key={'h2'} variant={'h2'}>
            <Skeleton />
          </Typography>
          <Typography component="div" key={'h2'} variant={'h2'}>
            <Skeleton />
          </Typography>
          <Typography component="div" key={'h2'} variant={'h2'}>
            <Skeleton />
          </Typography>
          </>}

          {!contentLoading && <>
            {testList.map((item,index)=>{
            return (<div className={`${styles.mainDivSec_BigSideCard}`} key={index} onClick={JoinQuiz.bind(this,item)}>
              <div className={`${styles.BigleftSideCardTitelSec}`}>
                <p className={`${styles.bigTitle}`}>{item?.subject?.title}</p>
                {item?.topic ? <p className={`${styles.smallTitle}`}>Chapter {item?.topic?.topic_index}. {item?.topic?.title}</p> : <p className={`${styles.smallTitle}`}>All Chapter</p>}
              </div>
              <div className={`${styles.BigrightSideCardTitelSec}`}>
                <div className={`${styles.BigSideCardTitelSec}`}>
                  <i data-feather="menu"></i>
                  <p className={`${styles.menutitle}`}>{item?.level}</p>
                </div>
                <div className={`${styles.GroupsSec}`}>
                  <div className={`${styles.questionsSec}`}>
                    <img src="/static/img/icons.svg" alt='' className={`${styles.iconcds}`} />
                    <p className={`${styles.numberTex}`}>{item?.total_question}</p>
                    <p className={`${styles.TextTex}`}>Questions</p>
                  </div>
                  <div className={`${styles.timesSec}`}>
                    <i data-feather="clock"></i>
                    <p className={`${styles.numberTex1}`}>{(moment.duration(item?.time_limit, 'minutes').hours() > 0) && moment.duration(item?.time_limit, 'minutes').hours() +' hr(s)'} {(moment.duration(item?.time_limit, 'minutes').minutes() > 0) && moment.duration(item?.time_limit, 'minutes').minutes() +' min(s)'}</p>
                  </div>
                </div>
              </div>
            </div>);
          })}
          {(pageCount > 0) && <Stack spacing={2} p>
            <Pagination count={pageCount} color="primary" page={page} onChange={(e,v)=>setPage(v)} />
            </Stack>}
          {(testList.length === 0) && <div className={`${styles.noData_FoundSec}`}>
            <div className={`${styles.noData_Found_SecUnder}`}>
              <img src='/static/img/no-datafound.png' alt='' />
              <p>No tests are available now</p>
            </div>
          </div>}
          </>}
        
          
        </div>
      </div>
    </div>
  </React.Fragment>);
}

export default withStudentAuth(TestList);