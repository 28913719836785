import React, { useState } from 'react';
import { ChevronDown, User, LogOut,Table , Sidebar, Book, File, Users, Grid, Mail } from 'react-feather';
import { Link } from '@mui/material';
import {  Link as RouterLink,useNavigate } from "react-router-dom";
import styles from './footer.module.css';
import {authData} from "../components/getAuth";

export default function Footer() {
  const auth = authData();
  const navigate = useNavigate();
  
  const [showUserDropdownMenu, setShowUserDropdownMenu] = useState(false);
  const [showBoardDropdownMenu, setShowBoardDropdownMenu] = useState(false);
  const [showClassDropdownMenu, setShowClassDropdownMenu] = useState(false);
  const [showSubjectDropdownMenu, setShowSubjectDropdownMenu] = useState(false);
  const [showTopicDropdownMenu, setShowTopicDropdownMenu] = useState(false);
  const [showMaterialDropdownMenu, setShowMaterialDropdownMenu] = useState(false);
  const [showFAQDropdownMenu, setShowFAQDropdownMenu] = useState(false);
  
  const logout = ()=>{
    localStorage.removeItem('authDataAceNew');
    navigate('/admin/login');
  }
  
  return (<footer className={`${styles.FooterMain}`}>
    <div className={`${styles.SideAvatarMain}`}>
      <div className={`${styles.SideAvatarMainUnder}`}>
        <div className={`${styles.SideAvatarSec}`}>
          <div className={`${styles.SideAvatarSecUnder}`}>
            <div className={`${styles.SideAvatar}`}><User/></div>
            <span className={`${styles.UserActiveState}`}></span>
          </div>
        </div>
        <div className={`${styles.SideUserName}`}>
          <span>{auth?.title}</span>
        </div>
      </div>
    </div>
    <div className={`${styles.SideMenuLinkSec}`}>
      <div className={`${styles.SideMenuLinkSecUnder}`}>
        <ul>
          <li onClick={() => setShowUserDropdownMenu(v => !v)} className={`${styles.DropDownSec} ${showUserDropdownMenu ? styles.Open : ''}`}>
            <Link>
              <User/>
              <p className={`${styles.SideLinkText}`}>Admins</p>
              <ChevronDown className={`${styles.DownIndi}`} />
            </Link>
            <ul className={`${styles.DropDownList}`}>
              <li>
                <RouterLink to={'/admin/user/list'}>List</RouterLink>
              </li>
              <li>
                <RouterLink to={'/admin/user/add'}>Add</RouterLink>
              </li>
            </ul>
          </li>
          <li onClick={() => setShowBoardDropdownMenu(v => !v)} className={`${styles.DropDownSec} ${showBoardDropdownMenu ? styles.Open : ''}`}>
            <Link>
              <Sidebar/>
              <p className={`${styles.SideLinkText}`}>Boards</p>
              <ChevronDown className={`${styles.DownIndi}`} />
            </Link>
            <ul className={`${styles.DropDownList}`}>
              <li>
                <RouterLink to={'/admin/board/list'}>List</RouterLink>
              </li>
              <li>
                <RouterLink to={'/admin/board/add'}>Add</RouterLink>
              </li>
            </ul>
          </li>
          <li onClick={() => setShowClassDropdownMenu(v => !v)} className={`${styles.DropDownSec} ${showClassDropdownMenu ? styles.Open : ''}`}>
            <Link>
              <Table/>
              <p className={`${styles.SideLinkText}`}>Classes</p>
              <ChevronDown className={`${styles.DownIndi}`} />
            </Link>
            <ul className={`${styles.DropDownList}`}>
              <li>
                <RouterLink to={'/admin/class/list'}>List</RouterLink>
              </li>
              <li>
                <RouterLink to={'/admin/class/add'}>Add</RouterLink>
              </li>
            </ul>
          </li>
          <li onClick={() => setShowSubjectDropdownMenu(v => !v)} className={`${styles.DropDownSec} ${showSubjectDropdownMenu ? styles.Open : ''}`}>
            <Link>
              <Book/>
              <p className={`${styles.SideLinkText}`}>Subjects</p>
              <ChevronDown className={`${styles.DownIndi}`} />
            </Link>
            <ul className={`${styles.DropDownList}`}>
              <li>
                <RouterLink to={'/admin/subject/list'}>List</RouterLink>
              </li>
              <li>
                <RouterLink to={'/admin/subject/add'}>Add</RouterLink>
              </li>
            </ul>
          </li>
          <li onClick={() => setShowTopicDropdownMenu(v => !v)} className={`${styles.DropDownSec} ${showTopicDropdownMenu ? styles.Open : ''}`}>
            <Link>
              <File/>
              <p className={`${styles.SideLinkText}`}>Topics</p>
              <ChevronDown className={`${styles.DownIndi}`} />
            </Link>
            <ul className={`${styles.DropDownList}`}>
              <li>
                <RouterLink to={'/admin/topic/list'}>List</RouterLink>
              </li>
              <li>
                <RouterLink to={'/admin/topic/add'}>Add</RouterLink>
              </li>
            </ul>
          </li>
          <li onClick={() => setShowMaterialDropdownMenu(v => !v)} className={`${styles.DropDownSec} ${showMaterialDropdownMenu ? styles.Open : ''}`}>
            <Link>
              <File/>
              <p className={`${styles.SideLinkText}`}>Study Materials</p>
              <ChevronDown className={`${styles.DownIndi}`} />
            </Link>
            <ul className={`${styles.DropDownList}`}>
              <li>
                <RouterLink to={'/admin/study-material/list'}>List</RouterLink>
              </li>
              <li>
                <RouterLink to={'/admin/study-material/add'}>Add</RouterLink>
              </li>
            </ul>
          </li>
          <li onClick={() => setShowFAQDropdownMenu(v => !v)} className={`${styles.DropDownSec} ${showFAQDropdownMenu ? styles.Open : ''}`}>
            <Link>
              <File/>
              <p className={`${styles.SideLinkText}`}>FAQs</p>
              <ChevronDown className={`${styles.DownIndi}`} />
            </Link>
            <ul className={`${styles.DropDownList}`}>
              <li>
                <RouterLink to={'/admin/faq/list'}>List</RouterLink>
              </li>
              <li>
                <RouterLink to={'/admin/faq/add'}>Add</RouterLink>
              </li>
            </ul>
          </li>
          <li>
            <RouterLink to={'/admin/student/list'}>
              <Users />
              <p className={`${styles.SideLinkText}`}>Students</p>
            </RouterLink>
          </li>
          <li>
            <RouterLink to={'/admin/mentor/list'}>
              <Grid/>
              <p className={`${styles.SideLinkText}`}>Mentors</p>
            </RouterLink>
          </li>
          <li>
            <RouterLink to={'/admin/contact-us'}>
              <Mail />
              <p className={`${styles.SideLinkText}`}>Contact Us</p>
            </RouterLink>
          </li>
          <li>
            <Link onClick={logout}>
              <LogOut/>
              <p className={`${styles.SideLinkText}`}>Logout</p>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  </footer>);
}