import React, { useEffect, useState } from 'react';
import styles from './testlist.module.css';
import withStudentAuth from '../../components/withStudentAuth';
import Header2 from '../../components/header2';
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import { authData } from '../../components/getAuth';
import * as moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography, Skeleton, Stack, Pagination } from '@mui/material';

const TestList = () => {
  const auth = authData();
  const navigate = useNavigate();
  const perPage = 10;
  const { id } = useParams();

  const [testList, setTestList] = useState([]);
  const [contentLoading, setContentLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    function getTestListTemp() {
      setContentLoading(true);
      let params = {};
      if (id) {
        params = { ...params, group_id: id };
      }
      params = { ...params, perPage: perPage, page: 1 };
      axios.get(process.env.REACT_APP_API_URL + 'student/test-list', {
        params,
        paramsSerializer: function paramsSerializer(params) {
          return Object.entries(Object.assign({}, params)).map(([key, value]) => `${key}=${value}`).join('&');
        },
        headers: { 'x-access-token': auth?.token }
      }).then(response => {
        setContentLoading(false);
        setTestList(response.data.data);

        let pageCount = response.data.totalTest / perPage;
        if (pageCount > parseInt(pageCount))
          pageCount = parseInt(pageCount) + 1;

        setPageCount(pageCount);
      }).catch(error => {
        setContentLoading(false);
        setTestList([]);
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Error occured. Please try again");
        }
      });
    }
    getTestListTemp();
  }, [id, auth?.token]);

  const JoinQuiz = (item) => {
    axios.get(process.env.REACT_APP_API_URL + 'student/check-quiz/' + item._id, {
      headers: { 'x-access-token': auth?.token }
    }).then(response => {
      if (item.is_restrict) {
        navigate('/student/join-quiz/' + item?._id);
      } else {
        navigate('/student/quiz/' + item?._id);
      }
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error occured. Please try again");
      }
    });
  }

  useEffect(() => {
    const getTestList = (sParams) => {
      setContentLoading(true);
      let params = {};
      params = { ...params, group_id: id };
      params = { ...params, perPage: perPage, page: page };
      axios.get(process.env.REACT_APP_API_URL + 'student/test-list', {
        params,
        paramsSerializer: function paramsSerializer(params) {
          return Object.entries(Object.assign({}, params)).map(([key, value]) => `${key}=${value}`).join('&');
        },
        headers: { 'x-access-token': auth?.token }
      }).then(response => {
        setContentLoading(false);
        setTestList(response.data.data);

        let pageCount = response.data.totalTest / perPage;
        if (pageCount > parseInt(pageCount))
          pageCount = parseInt(pageCount) + 1;

        setPageCount(pageCount);
      }).catch(error => {
        setContentLoading(false);
        setTestList([]);
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Error occured. Please try again");
        }
      });
    }

    getTestList();
  }, [page, id, auth?.token]);

  return (<React.Fragment>
    <Header2 />
    <div className={`${styles.Container}`}>
      <div className={`${styles.viewAll_Sec}`}>
        <p className={`${styles.viewAll_LeftSideText}`}>Test List</p>
      </div>
    </div>

    <div className={`${styles.Container}`}>
      <div className={`${styles.main_DivSec}`}>
        <div className={`${styles.mainDivSec_BigSide}`}>
          {contentLoading && <>
            <Typography component="div" key={'h2'} variant={'h2'}>
              <Skeleton />
            </Typography>
            <Typography component="div" key={'h2'} variant={'h2'}>
              <Skeleton />
            </Typography>
            <Typography component="div" key={'h2'} variant={'h2'}>
              <Skeleton />
            </Typography>
            <Typography component="div" key={'h2'} variant={'h2'}>
              <Skeleton />
            </Typography>
            <Typography component="div" key={'h2'} variant={'h2'}>
              <Skeleton />
            </Typography>
            <Typography component="div" key={'h2'} variant={'h2'}>
              <Skeleton />
            </Typography>
            <Typography component="div" key={'h2'} variant={'h2'}>
              <Skeleton />
            </Typography>
            <Typography component="div" key={'h2'} variant={'h2'}>
              <Skeleton />
            </Typography>
          </>}

          {!contentLoading && <>
            {testList.map((item, index) => {
              return (<div className={`${styles.mainDivSec_BigSideCard}`} key={index} onClick={JoinQuiz.bind(this, item)}>
                <div className={`${styles.BigleftSideCardTitelSec}`}>
                  <p className={`${styles.bigTitle}`}>{item?.subject?.title}</p>
                  {item?.topic ? <p className={`${styles.smallTitle}`}>Chapter {item?.topic?.topic_index}. {item?.topic?.title}</p> : <p className={`${styles.smallTitle}`}>All Chapter</p>}
                </div>
                <div className={`${styles.BigrightSideCardTitelSec}`}>
                  <div className={`${styles.BigSideCardTitelSec}`}>
                    <i data-feather="menu"></i>
                    <p className={`${styles.menutitle}`}>{item?.level}</p>
                  </div>
                  <div className={`${styles.GroupsSec}`}>
                    <div className={`${styles.questionsSec}`}>
                      <img src="/static/img/icons.svg" alt='' className={`${styles.iconcds}`} />
                      <p className={`${styles.numberTex}`}>{item?.total_question}</p>
                      <p className={`${styles.TextTex}`}>Questions</p>
                    </div>
                    <div className={`${styles.timesSec}`}>
                      <i data-feather="clock"></i>
                      <p className={`${styles.numberTex1}`}>{(moment.duration(item?.time_limit, 'minutes').hours() > 0) && moment.duration(item?.time_limit, 'minutes').hours() + ' hr(s)'} {(moment.duration(item?.time_limit, 'minutes').minutes() > 0) && moment.duration(item?.time_limit, 'minutes').minutes() + ' min(s)'}</p>
                    </div>
                  </div>
                </div>
              </div>);
            })}
            {(pageCount > 0) && <Stack spacing={2} p>
              <Pagination count={pageCount} color="primary" page={page} onChange={(e, v) => setPage(v)} />
            </Stack>}
            {(testList.length === 0) && <div className={`${styles.noData_FoundSec}`}>
              <div className={`${styles.noData_Found_SecUnder}`}>
                <img src='/static/img/no-datafound.png' alt='' />
                <p>No tests are available now</p>
              </div>
            </div>}
          </>}


        </div>
      </div>
    </div>
  </React.Fragment>);
}

export default withStudentAuth(TestList);