import React from 'react';
import styles from './list.module.css';
import {  Link } from "react-router-dom";
import { Typography, Breadcrumbs, TextField, Button } from '@mui/material';
import DataTable from 'react-data-table-component';
import { X } from 'react-feather';
import axios from 'axios';
import { TableLoader } from  '../../components/LoaderC';
import Loader from  '../../components/Loader';
import withAdminAuth from "../../components/withAdminAuth";
import {authData} from "../../components/getAuth";
import * as moment from 'moment';

class ContactUsList extends React.Component {
  constructor({ match, ...props }) {
    super(...arguments);
    this.state = { allData: [], data: [],filterText:'',modalShow:false,loading:false, contentLoading:true, currentItem:0  };

    this.auth = authData();

    this.columns = [
      {
        name: 'Name',
        selector: row => row.first_name+' '+row.last_name,
        sortable: true,
      },
      {
        name: 'Email',
        selector: row => row.email,
        sortable: true,
      },
      {
        name: 'Phone',
        selector: row => row.phone,
        sortable: true,
      },
      {
        name: 'Message Body',
        selector: row => row.message,
        sortable: false,
      },
      {
        name: 'Submitted At',
        selector: row => moment(row.createdAt).format('DD/MM/YYYY hh:mmA'),
        sortable: false,
      }
    ];
  }

  async componentDidMount() {
    try {
      axios(process.env.REACT_APP_API_URL + 'users/contact-us/list',{
        headers: {'x-access-token': this.auth?.token}
      }).then(response => {

        let allData = response.data.data;

        this.setState({ ...this.state, allData: allData, data: allData,contentLoading:false});
      }).catch(error=>{
        this.setState({ ...this.state, contentLoading:false});
      });
    } catch (error) {
      this.setState({ ...this.state, contentLoading:false});
    }
  }
  onFilter(e){
    this.setState({ ...this.state, filterText: e.target.value },this.filterHandler);
  }

  clearFilter(){
    this.setState({ ...this.state, filterText: '' },this.filterHandler);
  }

  filterHandler() {
    let filteredData = this.state.allData;
    if (this.state.filterText !== '') {
      let inputVal = this.state.filterText;
      filteredData = filteredData.filter((item) => {
        return item.first_name.toLowerCase().includes(inputVal) || item.last_name.toLowerCase().includes(inputVal) || item.email.toLowerCase().includes(inputVal) || item.phone.toLowerCase().includes(inputVal);
      });
    }
    this.setState({ ...this.state, data: filteredData });
  }

  render() {
    
    return (<>
      {this.state.loading && <Loader />}
      {this.state.contentLoading && <div className="LoaderCard"><div className="Loader TableLoader"><TableLoader /></div></div>}
      {!this.state.contentLoading && <div className={`${styles.MainDiv}`}>
        <div className={`${styles.PageTitleWrap}`}>
          <p className={`${styles.PageTitle}`}>Student List</p>
          <Breadcrumbs aria-label="breadcrumb" className='breadcrumb'>
            <Link color="inherit" to="/admin/dashboard">Dashboard</Link>
            <Link color="inherit" to={'/admin/student/list'}>Student</Link>
            <Typography color="text.primary">List</Typography>
          </Breadcrumbs>
        </div>
        
        <div className={`${styles.MainCard}`}>
          <div className='TableFilterHead'>
            <div className="DataTableSearch">
              <TextField id="search" type="text" placeholder="Search" aria-label="Search Input" value={this.state.filterText} onChange={this.onFilter.bind(this)} />
              {this.state.filterText && <Button type="button"  className="CloseBU" onClick={this.clearFilter.bind(this)}><X/></Button>}
            </div>
          </div>
          
          <div className='TableContent'>
            <DataTable className='DataTable'
              columns={this.columns}
              data={this.state.data}
              pagination
            />
          </div>
        </div>
      </div>}
    </>);
  }
}

export default withAdminAuth(ContactUsList);
