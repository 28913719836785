export const StorageGetItem = (key) =>{
    let result=JSON.parse(localStorage.getItem(key));

    if(result){
        if(result.expireTime<=Date.now()){
            localStorage.removeItem(key);
            return null;
        }
        return result.data;
    }
    
    return null;
}

export const StorageSetItem = (key, value, maxAge=24*60*60*1000) =>{
    let result= { data:value };
    if(maxAge){
        result.expireTime=Date.now()+maxAge;
    }
    localStorage.setItem(key,JSON.stringify(result));
}