import React from 'react';
import styles from './classarea.module.css';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { useEffect, useState } from 'react';
import { toast } from 'react-smart-toaster';

function ClassArea() {
    const navigate = useNavigate();
    const [classList, setClassList] = useState([]);
    
    useEffect(()=>{
        axios.get(process.env.REACT_APP_API_URL + 'public/class-list').then(response => {
            let classList = [].concat(response.data.data).sort((a, b) => parseInt(a.title.replace(/[^0-9]/g,"")) > parseInt(b.title.replace(/[^0-9]/g,"")) ? 1 : -1);
            setClassList(classList);
        }).catch(error => {
            if(error && error.response && error.response.data && error.response.data.message){
                toast.error(error.response.data.message);
            }else{
                toast.error("Error occured. Please try again");
            }
        });
    },[]);

    const openClassTest = (item) =>{
        localStorage.removeItem("testFilterData");
        localStorage.setItem("testFilterData",JSON.stringify({class_id: item._id}));
        navigate('/student/test-list');
    }

    return (<div className={`${styles.Container}`}>
        <div className={`${styles.Topic_SpecificMainArea}`}>
            <div className={`${styles.Topic_SpecificHeading_ButtomSec}`}>
                <div>
                    <p className={`${styles.Topic_SpecificTitelSmall}`}>Topic Specific tests</p>
                    <p className={`${styles.Topic_SpecificTitelBig}`}>Master any topic from our pool of tests</p>
                </div>
                <Link to={'/student/explore-tests'} className={`${styles.Topic_SpecificLoginBU}`}>Explore tests</Link>
            </div>
            <div className={`${styles.Row}`}>
                {classList.map((item,index) => {
                    let className = `${styles.Topic_Specific_ClassList}`;
                    if(index%5 === 1){
                        className += ` ${styles.greenColr}`;
                    }
                    if(index%5 === 2){
                        className += ` ${styles.blueColr}`;
                    }
                    if(index%5 === 3){
                        className += ` ${styles.purpleColr}`;
                    }
                    if(index%5 === 4){
                        className += ` ${styles.blueColr}`;
                    }
                    return (<div className={`${styles.Topic_SpecificCard}`} key={index}>
                        <div className={className} onClick={() => openClassTest(item)}>
                            <p className={`${styles.Topic_Specific_ClassNub}`}>{item.title}</p>
                            <p className={`${styles.Topic_Specific_ClassTex}`}>{item.total_topic} topic(s) . {parseInt(item.total_hours/60)} hour(s)</p>
                        </div>
                    </div>);
                })}
            </div>
        </div>
    </div>);
}

export default ClassArea;