import React, { useEffect, useState,Fragment } from 'react';
import styles from './step3.module.css';
import Header2 from '../../components/header2';
import withMentorAuth from '../../components/withMentorAuth';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Edit, Menu, Trash2 } from 'react-feather';
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import { authData } from '../../components/getAuth';
import { Checkbox, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';

const CreateTestStep3 = () => {
  const auth = authData();
  const navigate = useNavigate();
  const { id } = useParams();
  const [testDet, setTestDet] = useState({});
  const [questionList, setQuestionList] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    function getTest() {
      axios(process.env.REACT_APP_API_URL + 'mentor/test/' + id, {
        headers: { 'x-access-token': auth?.token }
      }).then(response => {
        setTestDet(response.data.data);
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Error occured. Please try again");
        }
      });
    }
    function getQuestionList() {
      axios(process.env.REACT_APP_API_URL + 'mentor/questions/' + id, {
        headers: { 'x-access-token': auth?.token }
      }).then(response => {
        setQuestionList(response.data.data);
        let totalTemp = 0;
        response.data.data.forEach(item =>{
          totalTemp += parseInt(item.marks);
        });
        setTotal(totalTemp);
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Error occured. Please try again");
        }
      });
    }

    if (auth?.token) {
      getTest();
      getQuestionList();
    }
  }, [auth?.token, id]);
  const finishTest = () => {
    axios.put(process.env.REACT_APP_API_URL + 'mentor/update-test/' + id, { status: 1 }, {
      headers: { 'x-access-token': auth?.token }
    }).then(response => {
      navigate('/mentor/create-test/last-step/'+id);
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error occured. Please try again");
      }
    });
  }

  const handleFileChange = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    const postData = new FormData();
    postData.append('file', e.target.files[0]);

    axios.post(process.env.REACT_APP_API_URL + 'mentor/upload-question-csv/' + id, postData, {
      headers: { 'x-access-token': auth?.token }
    }).then(response => {
      window.location.reload();
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error occured. Please try again");
      }
    });
  };

  const deleteQues = (item) =>{
    axios.delete(process.env.REACT_APP_API_URL + 'mentor/question/' + item._id, {
      headers: { 'x-access-token': auth?.token }
    }).then(response => {
      toast.success(response.data.message);
      let questionListTemp = questionList;
      let questionListTemp2 = questionListTemp.filter(i => i !== item);
      setQuestionList(questionListTemp2);
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error occured. Please try again");
      }
    });
  }

  const openDetails = (qIndex) => {
    let questionListTemp = questionList.map((item,index)=>{
        if(qIndex === index){
            return {...item, isOpen: !item.isOpen };
        }
        return {...item, isOpen:false };
    });
    setQuestionList(questionListTemp);
};

  return (<React.Fragment>

    <div className={`${styles.background_Color}`}>

      <Header2 />

      <div className={`${styles.Container}`}>
        <div className={`${styles.MainSec_Area}`}>
          <div>
            <div className={`${styles.BigDiv_Sec_UnderBg}`}>
              <table className={`${styles.table}`}>
                <tr>
                  <th className={`${styles.th_Heading}`}>Questions</th>
                </tr>
                {questionList.map((item, index) => {
                  return (<Fragment key={index}>
                    <tr>
                      <td className={`${styles.td_SLNo}`}>{index + 1}</td>
                      <td>
                        <div className={`${styles.td_Group}`}>
                          <img src="/static/img/massgicon.svg" alt="" />
                          <p className={`${styles.blueTex}`}>{testDet?.subject?.title} :: </p>
                          <p className={`${styles.blackTex}`}>{item?.title}</p>
                        </div>
                      </td>
                      <td className={`${styles.align_End}`}>
                        <button className={`${styles.group_Icon}`} onClick={(e) => openDetails(index)}>
                          <Menu />
                        </button>
                        <Link className={`${styles.group_Icon}`} to={"/mentor/create-test/update-question/" + id + "/" + item._id}><Edit /></Link>
                        <button className={`${styles.group_Icon}`} onClick={(e) => deleteQues(item)}> <Trash2 /></button>
                        <p className={`${styles.td_SLNo}`}>{item.marks}</p>
                      </td>
                    </tr>
                    {(item?.isOpen) ? (<tr>
                      <td colSpan={3}>
                        <div className={`${styles.halfBig_Div}`}>
                          <p className={`${styles.Big_DivHeading}`} style={{whiteSpace: "pre-wrap"}}><strong>Ques {index + 1}:</strong> {item?.description}</p>
                          {(item?.image) && <img src={item?.image} alt='' style={{maxWidth:'100%', maxHeight: '300px'}} />}
                          <div className={`${styles.borderSec}`}></div>
                          <div>
                            {(item?.type === 'Single Select' || item?.type === 'Muliple Select' || item?.type === 'True/False') && <FormControl>
                              <RadioGroup>
                                {item?.options?.map((opt, ind) => {
                                  let className = `${styles.ansLabel}`;
                                  if(opt?.is_correct){
                                    className += ` ${styles.correctAnsLabel}`;
                                  }
                                  return (<>
                                  {item?.type === 'Single Select' && <FormControlLabel value={ind} control={<Radio />} checked={opt?.is_correct} label={opt?.title} key={ind} className={className} />}
                                  {item?.type === 'Muliple Select' && <FormControlLabel value={ind} control={<Checkbox />} checked={opt?.is_correct} label={opt?.title} key={ind} className={className} />}
                                  {item?.type === 'True/False' && <FormControlLabel value={ind} control={<Radio />} checked={opt?.is_correct} label={opt?.title} key={ind} className={className} />}
                                  {(opt?.image) && <img src={opt?.image} alt='' style={{maxWidth:'200px', maxHeight: '100px', marginLeft: '30px'}} />}
                                  </>);
                                })}
                              </RadioGroup>
                            </FormControl>}
                            {item?.type === 'Subjective' && <div>
                              <p className={`${styles.Big_DivHeading}`}><strong>Answer:</strong></p>
                              <div style={{'border': '1px solid #D9D9D9','width': '500px', 'height': '122px', borderRadius: '5px'}}>&nbsp;</div>
                            </div>}
                          </div>
                          {(item?.remark || item?.remark_image) && <div className={`${styles.remarkArea}`}>
                            <p className={`${styles.Headingremark}`}>Remark</p>
                            <p className={`${styles.subHeadingremark}`} style={{whiteSpace: "pre-wrap"}}>{item?.remark}</p>
                            {(item?.remark_image && item?.remark_image !== '') && <img src={item?.remark_image} alt='' style={{ maxWidth:'100%'}} />}
                          </div>}
                        </div>
                      </td>
                    </tr>) :null}
                  </Fragment>);
                })}
                {(questionList.length > 0) && <tr>
                  <td colSpan={3} style={{textAlign:'right', fontWeight:'bold'}}>Total: {total}</td>
                </tr>}
                {(questionList.length ===0) && <tr>
                  <div className={`${styles.noData_FoundSec}`}>
                    <div className={`${styles.noData_Found_SecUnder}`}>
                      <img src='/static/img/no-datafound.png' alt='' />
                      <p>No question are available now</p>
                    </div>
                  </div>
                </tr>}
              </table>
              <div className={`${styles.button_Group}`}>
                <Link to={"/mentor/create-test/create-question/" + id} className={`${styles.button_Cus}`}>Create Question</Link>

                <input style={{'display':'none'}} id="file" type="file" name="file" accept=".csv" onChange={handleFileChange} />
                <Link to={"/mentor/create-test/create-question-from-bank/" + id} className={`${styles.button_Cus} ${styles.button_Cus2}`}>Add question from question bank</Link>
                <label htmlFor="file" type='button' className={`${styles.button_Cus}`}>Upload CSV</label>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.ending_BUDiv}`}>
          <button type='button' className={`${styles.ending_BU}`} onClick={finishTest}>Publish</button>
        </div>
      </div>
    </div>
  </React.Fragment>)
}

export default withMentorAuth(CreateTestStep3);