import React, { useEffect, useState } from 'react';
import styles from './grouplist.module.css';
import { Link } from 'react-router-dom';
import Header2 from '../../components/header2';
import withMentorAuth from '../../components/withMentorAuth';
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import { authData } from '../../components/getAuth';

const Dashboard = () => {
  const auth = authData();
  const [groupList, setGroupList] = useState([]);

  useEffect(() => {
    function getGroups() {
      axios(process.env.REACT_APP_API_URL + 'mentor/all-groups', {
        headers: { 'x-access-token': auth?.token }
      }).then(response => {
        setGroupList(response.data.data);
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Error occured. Please try again");
        }
      });
    }

    if (auth?.token) {
      getGroups();
    }
  }, [auth?.token]);

  
  
  return (<React.Fragment>
    <div className={`${styles.background_Color}`}>
      <Header2 />

      <div className={`${styles.Container}`}>
        <div className={`${styles.my_DashboardMainArea}`}>
          <div className={`${styles.my_DashboardNam}`}>
            <p className={`${styles.my_DashboarHeading}`}>Hi {auth?.first_name},</p>
            <p className={`${styles.my_DashboarSubHeading}`}>My Groups</p>
          </div>
        </div>
      </div>

      <div className={`${styles.Container}`}>
        <div className={`${styles.Row}`}>

          <div className={`${styles.BigDiv_Sec}`}>

            <div className={`${styles.text_ButtonGrop}`}>
              <div className={`${styles.BigDiv_Sec_UnderSmL}`}>
                <div className={`${styles.UnderSmLContGroup}`}>
                  <p className={`${styles.UnderSmLbigTitle}`}>My Groups</p>
                </div>
              </div>
              <div className={`${styles.UnderSmLContGroup2}`}>
                <Link to={'/mentor/create-group'} className={`${styles.My_TestsBU}`}><img src="/static/img/plesicons.svg" alt="" />Create New Group</Link>
              </div>
            </div>

            <div className={`${styles.Row}`}>
              {groupList.map(item=>{
                return <Link to={'/mentor/tests/'+item?._id} className={`${styles.my_TestsDiv}`} key={item._id}>
                  <div>
                    <div className={`${styles.my_TestsUnder}`}>
                      <p className={`${styles.Subject}`}>{item?.classes}</p>
                      <p className={`${styles.my_TestsAlgebraTex} ${styles.my_TestsAlgebraTex2}`}>{item?.title}</p>
                      <p className={`${styles.my_TestsAlgebraTex}`}>{item?.description}</p>
                    </div>
                  </div>
                </Link>
              })}

              {(groupList.length === 0) && <div style={{width:'100%', margin: '14px 8px 0'}}>
                <div className={`${styles.noData_FoundSec}`} style={{borderRadius:'8px'}}>
                  <div className={`${styles.noData_Found_SecUnder}`}>
                    <img src='/static/img/no-datafound.png' alt='' />
                    <p>No groups are available now</p>
                  </div>
                </div>
              </div>}

            </div>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>)
}

export default withMentorAuth(Dashboard);