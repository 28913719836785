import { Navigate } from "react-router-dom";
import { StorageGetItem } from "../../admin/components/StorageComp";
const withMentorAuth = (WrappedComponent) => {
  return (props) => {
    if (typeof window !== "undefined") {
      const authDataAce =  StorageGetItem("authDataAceNew");
      if(authDataAce){
        let authDataAceArr= JSON.parse(authDataAce);
        if(authDataAceArr?.role === 'Mentor'){
          return <WrappedComponent {...props} />;
        }else if(authDataAceArr?.role === 'Admin'){
          return <Navigate to={'/admin/user/list'} />;
        }else if(authDataAceArr?.role === 'Student'){
          return <Navigate to={'/student/dashboard'} />;
        }else{
          return <Navigate to={'/home'} />;
        }
      }else{
        return <Navigate to={'/login'} />
      }
    }
    return null;
  };
};

export default withMentorAuth;