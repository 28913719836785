import Dashboard from '../frontend/pages/dashboard/dashboard';
import Home from '../frontend/pages/home/home';
import Materials from '../frontend/pages/materials/materials';
import JoinQuiz from '../frontend/pages/joinquiz/joinquiz';
import Login from '../frontend/pages/login/login';
import Quiz from '../frontend/pages/quiz/quiz';
import QuizDetails from '../frontend/pages/quizdetails/quizdetails';
import QuizResult from '../frontend/pages/quizresult/quizresult';
import MentorSignup from '../frontend/pages/mentorsignup/mentorsignup';
import Signup from '../frontend/pages/signup/signup';
import Signup2 from '../frontend/pages/signup2/signup2';
import MyDashbord from '../frontend/pages/mydashboard/mydashboard';
import TestList from '../frontend/pages/testlist/testlist';
import AdminList from '../admin/pages/user/list';
import AdminAdd from '../admin/pages/user/add';
import AdminLogin from '../admin/pages/login/login';
import BoardList from '../admin/pages/board/list';
import BoardAdd from '../admin/pages/board/add';
import ClassList from '../admin/pages/class/list';
import ClassAdd from '../admin/pages/class/add';
import SubjectList from '../admin/pages/subject/list';
import SubjectAdd from '../admin/pages/subject/add';
import TopicList from '../admin/pages/topic/list';
import StudentList from '../admin/pages/student/list';
import MentorList from '../admin/pages/mentor/list';
import TopicAdd from '../admin/pages/topic/add';
import ExploreTest from '../frontend/pages/explore-test/explore-test';
import ExploreTestDetails from '../frontend/pages/explore-test-details/explore-test-details';
import MentorHome from '../mentor/pages/home/home';
import MentorDashboard from '../mentor/pages/dashboard/dashboard';
import MentorCreateTestStep1 from '../mentor/pages/createtest/step1';
import MentorCreateTestStep2 from '../mentor/pages/createtest/step2';
import MentorCreateTestStep3 from '../mentor/pages/createtest/step3';
import MentorViewTest from '../mentor/pages/createtest/view-test';
import MentorCreateQuestion from '../mentor/pages/createtest/createquestion';
import MentorCreateAnswer from '../mentor/pages/createtest/createanswers';
import MentorCreateTest4 from '../mentor/pages/createtest/laststep';
import MentorCreateQuestionFromBank from '../mentor/pages/createtest/createquestionfrombank';
import MentorCreateGroup from '../mentor/pages/creategroup/creategroup';
import MentorGropupList from '../mentor/pages/grouplist/grouplist';
import MentorTestList from '../mentor/pages/testlist/testlist';
import MentorGroupTestList from '../mentor/pages/testlistbygroup/testlist';
import MentorMyProfile from '../mentor/pages/myprofile/myprofile';
import MentorUpdateProfile from '../mentor/pages/updateprofile/updateprofile';
import MentorChangePassword from '../mentor/pages/changepassword/changepassword';
import MentorTestEvalute from '../mentor/pages/testevalute/testevalute';
import MentorTestEvalute2 from '../mentor/pages/testevalute2/testevalute2';
import StudentVault from '../frontend/pages/myvault/myvault';
import AddStudentVault from '../frontend/pages/addvault/addvault';
import StudyMaterialList from '../admin/pages/studymaterial/list';
import StudyMaterialAdd from '../admin/pages/studymaterial/add';
import FAQList from '../admin/pages/faq/list';
import FAQAdd from '../admin/pages/faq/add';
import FAQ from '../frontend/pages/faq/faq';
import Privacy from '../frontend/pages/privacy/privacy';
import ContactUs from '../frontend/pages/contactus/contactus';
import ContactUsAdmin from '../admin/pages/contactus/list';
import StudentMyProfile from '../frontend/pages/myprofile/myprofile';
import StudentMyTests from '../frontend/pages/mytests/mytests';
import StudentUpdateProfile from '../frontend/pages/updateprofile/updateprofile';
import StudentChangePassword from '../frontend/pages/changepassword/changepassword';
import StudentGropupList from '../frontend/pages/grouplist/grouplist';
import GroupTestList from '../frontend/pages/grouptestlist/testlist';
import MentorFAQ from '../mentor/pages/faq/faq';
import MentorContactUs from '../mentor/pages/contactus/contactus';
import MentorReport from '../mentor/pages/myreport/myreport';


const IndexRoutes = {
  path:'/',
  component: Home
};
const LoginRoutes = {
  path:'/login',
  component: Login
};
const SignupRoutes = {
  path:'/student/sign-up',
  component: Signup
};
const MentorSignupRoutes = {
  path:'/mentor/sign-up',
  component: MentorSignup
};
const Signup2Routes = {
  path:'/student/sign-up-2',
  component: Signup2
};

const HomeRoutes = {
  path:'/home',
  component: Home
};

const MaterialRoutes = {
  path:'/material-list',
  component: Materials
};

const AdminLoginRoutes = {
  path:'/admin/login',
  component: AdminLogin
};

const FAQRoutes = {
  path:'/faq',
  component: FAQ
};

const ContactUsRoutes = {
  path:'/contact-us',
  component: ContactUs
};

const PrivacyRoutes = {
  path:'/privacy',
  component: Privacy
};

export const WithoutThemeRouteList = [
  LoginRoutes,
  SignupRoutes,
  MentorSignupRoutes,
  Signup2Routes,
  IndexRoutes,
  HomeRoutes,
  MaterialRoutes,
  AdminLoginRoutes,
  FAQRoutes,
  ContactUsRoutes,
  PrivacyRoutes
];

export const AdminRoutes = {
  routeList:[
    {
      path:'/user/list',
      component: AdminList
    },
    {
      path:'/user/add',
      component: AdminAdd
    },
    {
      path:'/user/edit/:id',
      component: AdminAdd
    },
    {
      path:'/board/list',
      component: BoardList
    },
    {
      path:'/board/add',
      component: BoardAdd
    },
    {
      path:'/board/edit/:id',
      component: BoardAdd
    },
    {
      path:'/class/list',
      component: ClassList
    },
    {
      path:'/class/add',
      component: ClassAdd
    },
    {
      path:'/class/edit/:id',
      component: ClassAdd
    },
    {
      path:'/subject/list',
      component: SubjectList
    },
    {
      path:'/subject/add',
      component: SubjectAdd
    },
    {
      path:'/subject/edit/:id',
      component: SubjectAdd
    },
    {
      path:'/topic/list',
      component: TopicList
    },
    {
      path:'/topic/add',
      component: TopicAdd
    },
    {
      path:'/topic/edit/:id',
      component: TopicAdd
    },
    {
      path:'/study-material/list',
      component: StudyMaterialList
    },
    {
      path:'/study-material/add',
      component: StudyMaterialAdd
    },
    {
      path:'/study-material/edit/:id',
      component: StudyMaterialAdd
    },
    {
      path:'/student/list',
      component: StudentList
    },
    {
      path:'/mentor/list',
      component: MentorList
    },
    {
      path:'/faq/list',
      component: FAQList
    },
    {
      path:'/faq/add',
      component: FAQAdd
    },
    {
      path:'/faq/edit/:id',
      component: FAQAdd
    },
    {
      path:'/contact-us',
      component: ContactUsAdmin
    },
  ]
};

export const MentorRoutes = {
  routeList:[
    {
      path:'/',
      component: MentorHome
    },{
      path:'/dashboard',
      component: MentorDashboard
    },{
      path:'/groups',
      component: MentorGropupList
    },{
      path:'/tests',
      component: MentorTestList
    },{
      path:'/tests/:group_id',
      component: MentorGroupTestList
    },{
      path:'/test-evalute/:id',
      component: MentorTestEvalute
    },{
      path:'/student-test-evalute/:id',
      component: MentorTestEvalute2
    },{
      path:'/create-group',
      component: MentorCreateGroup
    },{
      path:'/create-test',
      component: MentorCreateTestStep1
    },{
      path:'/create-test/step-1/:id',
      component: MentorCreateTestStep1
    },{
      path:'/create-test/step-2/:id',
      component: MentorCreateTestStep2
    },{
      path:'/create-test/step-3/:id',
      component: MentorCreateTestStep3
    },{
      path:'/view-test/:id',
      component: MentorViewTest
    },{
      path:'/create-test/create-question/:id',
      component: MentorCreateQuestion
    },{
      path:'/create-test/create-question-from-bank/:id',
      component: MentorCreateQuestionFromBank
    },{
      path:'/create-test/update-question/:id/:qId',
      component: MentorCreateQuestion
    },{
      path:'/create-test/set-answer/:tId/:qId',
      component: MentorCreateAnswer
    },{
      path:'/create-test/last-step/:id',
      component: MentorCreateTest4
    },{
      path:'/my-profile',
      component: MentorMyProfile
    },{
      path:'/update-profile',
      component: MentorUpdateProfile
    },{
      path:'/change-password',
      component: MentorChangePassword
    },{
      path:'/faq',
      component: MentorFAQ
    },{
      path:'/contact-us',
      component: MentorContactUs
    },{
      path:'/my-report',
      component: MentorReport
    }
  ]
};

export const StudentRoutes = {
  routeList:[
    {
      path:'/dashboard',
      component: Dashboard
    },{
      path:'/explore-tests',
      component: ExploreTest
    },{
      path:'/explore-tests/:id',
      component: ExploreTestDetails
    },{
      path:'/test-list',
      component: TestList
    },{
      path:'/join-quiz/:id',
      component: JoinQuiz
    },
    {
      path:'/quiz/:id',
      component: Quiz
    },
    {
      path:'/quiz-details/:id',
      component: QuizDetails
    },
    {
      path:'/quiz-result/:id',
      component: QuizResult
    },
    {
      path:'/my-reports',
      component: MyDashbord
    },
    {
      path:'/my-vault',
      component: StudentVault
    },
    {
      path:'/add-vault',
      component: AddStudentVault
    },{
      path:'/my-profile',
      component: StudentMyProfile
    },{
      path:'/update-profile',
      component: StudentUpdateProfile
    },{
      path:'/change-password',
      component: StudentChangePassword
    },{
      path:'/group-list',
      component: StudentGropupList
    },{
      path:'/group-test-list/:id',
      component: GroupTestList
    },{
      path:'/my-test',
      component: StudentMyTests
    }
  ]
};