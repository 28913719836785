import React from 'react';
import styles from './home.module.css';
import { Link } from 'react-router-dom';
import Header1 from '../../components/header1';
import withMentorAuth from '../../components/withMentorAuth';

const Home = () => {

  return (<React.Fragment>

    <Header1 />

    <div className={`${styles.Container}`}>
      <div className={`${styles.Explor_TestsMainArea}`}>
        <div className={`${styles.Explor_TestsTextSec}`}>
          <p className={`${styles.Explor_TestsTitelBig}`}>Create a Quiz and invite your<br /> students</p>
          <p className={`${styles.Explor_TestsTitelSmall}`}>Explore Topic specific tests</p>
          <Link to={'/mentor'} className={`${styles.Explor_TestsLoginBU}`}>Explore tests</Link>
        </div>
        <div className={`${styles.Explor_TestsImgSec}`}>
          <img src="/static/img/Frame1.svg" alt="" />
        </div>
      </div>
    </div>

    <div className={`${styles.Container}`}>
      <div className={`${styles.CreateA_QuizMainArea}`}>
        <div className={`${styles.CreateA_QuizTextSec}`}>
          <p className={`${styles.CreateA_QuizTitel}`}>Create And a Quiz and<br /> invite your students</p>
          <div className={`${styles.CreateA_QuizButton}`}>
            <Link to={'/mentor/create-test'} className={`${styles.CreateA_QuizLoginBU}`}>Create a Quiz</Link>
          </div>
        </div>
        <div className={`${styles.CreateA_QuizImgSec}`}>
          <img src="/static/img/Frame2.png" alt="" />
        </div>
      </div>
    </div>

    <div className={`${styles.Container}`}>
      <div className={`${styles.CreateA_QuizMainArea1}`}>
        <div className={`${styles.CreateA_QuizImgSec}`}>
          <img src="/static/img/Frame1.svg" alt="" />
        </div>
        <div className={`${styles.CreateA_QuizTextSec1}`}>
          <div className={`${styles.CreateA_QuizTextSec2}`}>
            <p className={`${styles.CreateA_QuizTitel1}`}>Create Groups where you can Craft <br />your dream class</p>
            <div className={`${styles.CreateA_QuizButton}`}>
              <button className={`${styles.CreateA_QuizLoginBU}`}>Create a Group</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </React.Fragment>)
}

export default withMentorAuth(Home);