import React from 'react';
import './App.css';
import MainRoutes from './routes/Routes';
import { SmartToaster, toast } from 'react-smart-toaster';

function App() {
  return (<React.Fragment>
    <MainRoutes />
    <SmartToaster store={toast} lightBackground={true} position={"top_center"} />
  </React.Fragment>);
}

export default App;
