import React from 'react';
import styles from './privacy.module.css';
import Header1 from '../../components/header1';
import Header2 from '../../components/header2';
import { authData } from '../../components/getAuth';

const Home = () => {
  const auth = authData();

  return (<React.Fragment>
    {auth ? <Header2 />:<Header1 />}
    
    <div className={`${styles.Container}`}>
        <div className={`${styles.my_DashboardMainArea}`}>
          <div className={`${styles.my_DashboardNam}`}>
            <p className={`${styles.my_DashboarHeading}`}>Privacy Policy for AcePariksha</p>
            <p>Last Updated: September 04, 2024</p>
          </div>
        </div>
      </div>

      <div className={`${styles.Container}`}>
        <div className={`${styles.my_DashboardMainArea}`}>
          <div className={`${styles.my_DashboardNam}`}>
            <p>Welcome to AcePariksha, an educational platform designed to provide users with access to tests and immediate results. Your privacy is important to us, and this Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you use our app.</p>
            <ol className={`${styles.OIClass}`}>
              <li className={`${styles.LIClass} ${styles.LIClassM}`}>Information We Collect</li>
              <ol className={`${styles.OIClass}`}>
                <li className={`${styles.LIClass}`}>
                  <p style={{display: 'contents', fontWeight: 500}}>Personal Information</p>
                  <p style={{margin:'5px 0 5px 20px'}}>We collect the following types of personal information when you register or use our app:</p>
                  <p style={{margin:'0 0 5px 20px'}}></p>
                  <p style={{margin:'0 0 0 20px'}}>- Name</p>
                  <p style={{margin:'0 0 0 20px'}}>- Email address</p>
                  <p style={{margin:'0 0 0 20px'}}>- Phone number</p>
                  <p style={{margin:'0 0 0 20px'}}>- User-generated content, including test answers and feedback</p>
                </li>
                <li className={`${styles.LIClass}`}>
                  <p style={{display: 'contents', fontWeight: 500}}>Usage Data</p>
                  <p style={{margin:'5px 0 5px 20px'}}>We automatically collect data about how you interact with our app, including:</p>
                  <p style={{margin:'0 0 5px 20px'}}></p>
                  <p style={{margin:'0 0 0 20px'}}>- Device information (e.g., device type, operating system, and unique device identifiers)</p>
                  <p style={{margin:'0 0 0 20px'}}>- IP address</p>
                  <p style={{margin:'0 0 0 20px'}}>- Browser type</p>
                  <p style={{margin:'0 0 0 20px'}}>- App usage data (e.g., pages visited, test attempts, time spent)</p>
                </li>
                <li className={`${styles.LIClass}`}>
                  <p style={{display: 'contents', fontWeight: 500}}>Cookies and Tracking Technologies</p>
                  <p style={{margin:'5px 0 5px 20px'}}>We use cookies and similar technologies to enhance your experience, store user preferences, and track app usage.</p>
                </li>
              </ol>
              <li className={`${styles.LIClass}`}>
                <p style={{display: 'contents', fontWeight: 600}}>How We Use Your Information</p>
                  <p style={{margin:'5px 0 5px 20px'}}>We use your personal information for the following purposes:</p>
                  <p style={{margin:'0 0 5px 20px'}}></p>
                  <p style={{margin:'0 0 0 20px'}}>- To provide and improve our educational services</p>
                  <p style={{margin:'0 0 0 20px'}}>- To process your test results and provide feedback</p>
                  <p style={{margin:'0 0 0 20px'}}>- To communicate with you regarding your account and updates</p>
                  <p style={{margin:'0 0 0 20px'}}>- To personalize your learning experience</p>
                  <p style={{margin:'0 0 0 20px'}}>- To analyze usage patterns and improve our app functionalit</p>
                  <p style={{margin:'0 0 0 20px'}}>- To prevent fraud and enhance the security of our platform</p>
              </li>
              <li className={`${styles.LIClass}`}>
                <p style={{display: 'contents', fontWeight: 600}}>Sharing Your Information</p>
                <p style={{margin:'5px 0 5px 20px'}}>We do not sell, rent, or trade your personal information to third parties. We may share your information in the following circumstances:</p>
                <p style={{margin:'0 0 5px 20px'}}></p>
                <p style={{margin:'0 0 0 20px'}}>- With Service Providers: We may share your data with third-party service providers who help us operate our app, process transactions, or provide customer support.</p>
                <p style={{margin:'0 0 0 20px'}}>- Legal Compliance: We may disclose your information if required by law or in response to valid requests by public authorities (e.g., a court or government agency).</p>
                <p style={{margin:'0 0 0 20px'}}>- Business Transfers: If we are involved in a merger, acquisition, or asset sale, your personal information may be transferred as part of that business transaction</p>
              </li>
              
              <li className={`${styles.LIClass}`}>
                <p style={{display: 'contents', fontWeight: 600}}>Data Security</p>
                <p style={{margin:'5px 0 5px 20px'}}>We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no internet transmission is completely secure, and we cannot guarantee absolute security.</p>
              </li>
              <li className={`${styles.LIClass}`}>
                <p style={{display: 'contents', fontWeight: 600}}>Your Rights</p>
                <p style={{margin:'5px 0 5px 20px'}}>You have the following rights regarding your personal information:</p>
                <p style={{margin:'0 0 5px 20px'}}></p>
                <p style={{margin:'0 0 0 20px'}}>- Access: You can request access to the personal information we hold about you.</p>
                <p style={{margin:'0 0 0 20px'}}>- Correction: You can request corrections to your personal information if it is inaccurate or incomplete.</p>
                <p style={{margin:'0 0 0 20px'}}>- Deletion: You can request the deletion of your personal information, subject to legal and contractual obligations.</p>
                <p style={{margin:'0 0 0 20px'}}>- Objection: You can object to our processing of your personal data for certain purposes.</p>
              </li>
              <li className={`${styles.LIClass}`}>
                <p style={{display: 'contents', fontWeight: 600}}>Children's Privacy</p>
                <p style={{margin:'5px 0 5px 20px'}}>Our app is intended for users aged 13 and older. We do not knowingly collect personal information from children under 13. For the students, We expect parent to login and assist the students for the tests. If you believe we have collected information from a child, please contact us immediately, and we will take steps to delete such information.</p>
              </li>
              <li className={`${styles.LIClass}`}>
                <p style={{display: 'contents', fontWeight: 600}}>Changes to This Privacy Policy</p>
                <p style={{margin:'5px 0 5px 20px'}}>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the updated policy will be effective as of the date indicated.</p>
              </li>
              <li className={`${styles.LIClass}`}>
                <p style={{display: 'contents', fontWeight: 600}}>Contact Us</p>
                <p style={{margin:'5px 0 5px 20px'}}>If you have any questions or concerns about this Privacy Policy, please contact us at:<br /><a href="https://acepariksha.com/">Ace Pariksha</a><br />Plot 38 & 50, Dwarkanagar, Rajendra Nagar, Hyderabad - 500086</p>
              </li>
            </ol>
          </div>
        </div>
      </div>
    
    
  </React.Fragment>);
}

export default Home;