import React, { useEffect, useState } from 'react';
import styles from './signup2.module.css';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Select from "react-select";
import { toast } from 'react-smart-toaster';
import { authData } from '../../components/getAuth';
import { StorageGetItem, StorageSetItem } from '../../../admin/components/StorageComp';

const Signup2 = () => {
  const auth = authData();
  const navigate = useNavigate();
  const { register, handleSubmit, control } = useForm();

  const onSubmit = (data) => {
    const authDataAce =  StorageGetItem("authDataAceNew");
    let postData = {};
    if(data?.board_id?.value){
      postData = {...postData, board_id: data?.board_id?.value};
    }
    if(data?.class_id?.value){
      postData = {...postData, class_id: data?.class_id?.value};
    }
    if(data?.subject_id?.value){
      postData = {...postData, subject_id: data?.subject_id?.value};
    }
    return axios.post(process.env.REACT_APP_API_URL + 'student/update', postData,{
      headers: {'x-access-token': auth?.token}
    }).then(response => {
      if(authDataAce){
        let authDataAceArr = JSON.parse(authDataAce);
        authDataAceArr ={...authDataAceArr, student_info: response.data.data};
        StorageSetItem("authDataAceNew", JSON.stringify(authDataAceArr),24*60*60*1000);
      }
      navigate('/student/dashboard');
    }).catch(error => {
      if(error && error.response && error.response.data && error.response.data.message){
        toast.error(error.response.data.message);
      }else{
        toast.error("Error occured. Please try again");
      }
    });
  }

  const [boardList, setBoardList] = useState([]);
  const [classList, setClassList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);

  useEffect(()=>{
    axios.get(process.env.REACT_APP_API_URL + 'public/boards').then(response => {
      let boardListTemp = response.data.data.map(i=> { return { value:i._id, label:i.title }; });
      setBoardList(boardListTemp);
    }).catch(error => {
      if(error && error.response && error.response.data && error.response.data.message){
        toast.error(error.response.data.message);
      }else{
        toast.error("Error occured. Please try again");
      }
    });
  },[]);

  useEffect(()=>{
    axios.get(process.env.REACT_APP_API_URL + 'public/classes').then(response => {
      let classListTemp = response.data.data.map(i=> { return { value:i._id, label:i.title }; });
      setClassList(classListTemp);
    }).catch(error => {
      if(error && error.response && error.response.data && error.response.data.message){
        toast.error(error.response.data.message);
      }else{
        toast.error("Error occured. Please try again");
      }
    });
  },[]);

  useEffect(()=>{
    axios.get(process.env.REACT_APP_API_URL + 'public/subjects').then(response => {
      let subjectListTemp = response.data.data.map(i=> { return { value:i._id, label:i.title }; });
      setSubjectList(subjectListTemp);
    }).catch(error => {
      if(error && error.response && error.response.data && error.response.data.message){
        toast.error(error.response.data.message);
      }else{
        toast.error("Error occured. Please try again");
      }
    });
  },[]);

  return (<React.Fragment>
    <div className={`${styles.login_MainDiv}`}>
      <div className={styles.login_BannerSec}>
        <img src='/static/img/Get_started_page_image.jpg' alt='' />
      </div>
      <div className={`${styles.login_FormSec}`}>
        <div className={`${styles.login_LohinForm}`}>
          <h2 className={`${styles.login_FormTitle} ${styles.Heading}`}>Get started</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`${styles.login_FormRow}`}>
              <div className={`${styles.login_FormFieldHalf}`}>
                <span className={`${styles.details}`}>Board</span>
                <label className={`${styles.login_FormLabel}`}>
                  <Controller control={control} className={`${styles.login_FormControl}`} options={boardList} {...register('board_id')} render={({ field }) => (
                    <Select {...field} options={boardList} />
                  )}/>
                </label>
              </div>
              <div className={`${styles.login_FormFieldHalf}`}>
                <span className={`${styles.details}`}>Class</span>
                <label className={`${styles.login_FormLabel}`}>
                  <Controller control={control} className={`${styles.login_FormControl}`} options={classList} {...register('class_id')} render={({ field }) => (
                    <Select {...field} options={classList} />
                  )}/>
                </label>
              </div>
              <div className={`${styles.login_FormFieldHalf}`}>
                <span className={`${styles.details}`}>What is you Favorite Subject?</span>
                <Controller control={control} className={`${styles.login_FormControl}`} options={subjectList} {...register('subject_id')} render={({ field }) => (
                    <Select {...field} options={subjectList} />
                  )}/>
              </div>
              <div className={`${styles.buttonGroup}`}>
                <input type="submit" value="Explore" className={`${styles.loginButton}`}/>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </React.Fragment>)
}

export default Signup2;