import React, { useEffect } from 'react';
import styles from './updateprofile.module.css';
import Header2 from '../../components/header2';
import withMentorAuth from '../../components/withMentorAuth';
import { useNavigate } from 'react-router-dom';
import { authData } from '../../components/getAuth';
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import { useForm } from 'react-hook-form';

const UpdateProfile = () => {
  const auth = authData();
  const navigate = useNavigate();

  const { handleSubmit, register, formState: { errors }, setValue } = useForm();

  useEffect(() => {
    function getMyProfile() {
      axios(process.env.REACT_APP_API_URL + 'mentor/my-profile', {
        headers: { 'x-access-token': auth?.token }
      }).then(response => {
        setValue('first_name',response.data.data?.first_name);
        setValue('last_name',response.data.data?.last_name);
        setValue('email',response.data.data?.email);
        setValue('phone',response.data.data?.phone);
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Error occured. Please try again");
        }
      });
    }

    if (auth?.token) {
      getMyProfile();
    }
  }, [auth?.token,setValue]);

  const onSubmit = (data) =>{
    axios.put(process.env.REACT_APP_API_URL + 'mentor/update-profile', data, {
      headers: { 'x-access-token': auth?.token }
    }).then(response => {
      navigate('/mentor/my-profile');
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error occured. Please try again");
      }
    });
  }

return (<React.Fragment>
    <div className={`${styles.background_Color}`}>
      <Header2 />

      <div className={`${styles.Container}`}>
        <div className={`${styles.create_AccomplishmentSec}`}>
          <p>Update Profile</p>
        </div>
        <div className={`${styles.general_FormBoxArea}`}>
          <div className={`${styles.general_TextAroSec}`}>
            <p className={`${styles.general_TextAroSecTitel}`}>General</p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
          <div className={`${styles.accomplishment_FormField}`}>
              <label className={`${styles.accomplishment_Label}`}>First Name*</label>
              <input type="text" placeholder="First Name" className={`${styles.accomplishment_inputTy}`} {...register("first_name",{ required: 'This field is required' })} />
              {errors?.first_name && <p className={`${styles.login_ErrorM}`}>{errors?.first_name?.message}</p>}
            </div>
            <div className={`${styles.accomplishment_FormField}`}>
              <label className={`${styles.accomplishment_Label}`}>Last Name*</label>
              <input type="text" placeholder="Last Name" className={`${styles.accomplishment_inputTy}`} {...register("last_name",{ required: 'This field is required' })} />
              {errors?.last_name && <p className={`${styles.login_ErrorM}`}>{errors?.last_name?.message}</p>}
            </div>
            <div className={`${styles.accomplishment_FormField}`}>
              <label className={`${styles.accomplishment_Label}`}>Email*</label>
              <input type="text" placeholder="Email" className={`${styles.accomplishment_inputTy}`} {...register("email",{ required: 'This field is required' })} readOnly />
              {errors?.email && <p className={`${styles.login_ErrorM}`}>{errors?.email?.message}</p>}
            </div>
            <div className={`${styles.accomplishment_FormField}`}>
              <label className={`${styles.accomplishment_Label}`}>Phone No*</label>
              <input type="text" placeholder="Phone No" className={`${styles.accomplishment_inputTy}`} {...register("phone",{ required: 'This field is required' })} />
              {errors?.phone && <p className={`${styles.login_ErrorM}`}>{errors?.phone?.message}</p>}
            </div>
            <div className={`${styles.ending_SaveSec}`}>
              <button type='submit' className={`${styles.ending_SaveBU}`}>Update</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </React.Fragment>)
}

export default withMentorAuth(UpdateProfile);