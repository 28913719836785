import React from "react";
import {BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { WithoutThemeRouteList,AdminRoutes, MentorRoutes, StudentRoutes } from "./index";
import AdminLayout from "../layouts/AdminLayout";
import Page404 from "../frontend/components/Page404";

const MainRoutes = () => {
    return (<Router>
        <Routes>
            {WithoutThemeRouteList?.map(({ component: Component, path }, index) => {
               return (<Route key={index} path={path} exact element={
                <Component  />
                } />)
            })}

            {AdminRoutes?.routeList?.map(({ component: Component, path }, index) => {
                return (<Route key={index} path={`/admin${path}`} exact element={
                    <AdminLayout>
                        <Component />
                    </AdminLayout>
                } />)
            })}

            {MentorRoutes?.routeList?.map(({ component: Component, path }, index) => {
                return (<Route key={index} path={`/mentor${path}`} exact element={
                    <Component />
                } />)
            })}

            {StudentRoutes?.routeList?.map(({ component: Component, path }, index) => {
                return (<Route key={index} path={`/student${path}`} exact element={
                    <Component />
                } />)
            })}

            <Route path="*" element={<Page404  />} />
        </Routes>
    </Router>
)};

export default MainRoutes;