import React, {useState,useEffect} from 'react';
import styles from './add.module.css';
import { Typography, Breadcrumbs,Button  } from '@mui/material';
import {  useNavigate,Link ,useParams} from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import { FromLoader } from  '../../components/LoaderC';
import Loader from  '../../components/Loader';
import withAdminAuth from "../../components/withAdminAuth";
import Select from "react-select";
import {authData} from "../../components/getAuth";

function StudyMaterialAdd() {
  const auth = authData();
  const navigate = useNavigate();
  let { id } = useParams();
  const isAddMode = !id;
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(false);
  const [classList, setClassList] = useState([]);
  const [allSubjectList, setAllSubjectList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [selectedData, setSelectedData] = useState({class: ''});
  const [itemDet, setItemDet] = useState({});
  const [preview, setPreview] = useState(null);

  let schema = yup.object().shape({
    title: yup.string().required('This field is Required'),
    class_id: yup.object().required('This field is Required'),
    subject_id: yup.object().required('This field is Required'),
    file: yup.string().required('This field is Required'),
  });
  
  const { register, handleSubmit, watch,setValue,  formState: { errors },control } = useForm({
    resolver: yupResolver(schema), mode: 'onChange'
  });

  useEffect(()=>{
    axios.get(process.env.REACT_APP_API_URL + 'public/classes').then(response => {
      let classListTemp = response.data.data.map(i=> { return { value:i._id, label:i.title }; });
      setClassList(classListTemp);
    }).catch(error => {
      if(error && error.response && error.response.data && error.response.data.message){
        toast.error(error.response.data.message);
      }else{
        toast.error("Error occured. Please try again");
      }
    });
  },[]);

  useEffect(()=>{
    axios.get(process.env.REACT_APP_API_URL + 'public/subjects').then(response => {
      let subjectListTemp = response.data.data;
      setAllSubjectList(subjectListTemp);
    }).catch(error => {
      if(error && error.response && error.response.data && error.response.data.message){
        toast.error(error.response.data.message);
      }else{
        toast.error("Error occured. Please try again");
      }
    });
  },[]);

  useEffect(()=>{
    if(selectedData?.class !== ''){
      let subjectListTemp = allSubjectList.filter(i => (i.classes.indexOf(selectedData?.class) > -1)).map(i=> { return { value:i._id, label:i.title }; });
      setSubjectList(subjectListTemp);
      subjectListTemp?.forEach(item=>{
        if(item.value === itemDet.subject_id?._id){
          setValue('subject_id',item);
        }
      });
    }
  },[allSubjectList,selectedData, itemDet.subject_id, setValue])

  useEffect(()=>{
    if(!isAddMode){
        axios.get(process.env.REACT_APP_API_URL + 'study-materials/'+id,{
          headers: {'x-access-token': auth?.token}
        }).then(response => {
          setContentLoading(false);
          let itemData = response.data.data;
          setItemDet(itemData);
          setValue('title',itemData.title);
          setValue('file',itemData.file);
          setPreview(itemData.file);
          setSelectedData({class: itemData.class_id?._id});
          classList?.forEach(item=>{
            if(item.value === itemData.class_id?._id){
              setValue('class_id',item);
            }
          });
        }).catch(error => {
          if(error && error.response && error.response.data && error.response.data.message){
            toast.error(error.response.data.message);
          }else{
            toast.error("Error occured. Please try again");
          }
        });
    }
  },[isAddMode,id,setValue,classList,auth?.token]);
  
  async function onSubmit(data) {
    data = {...data, status:1, class_id: data?.class_id?.value, subject_id: data?.subject_id?.value};

    const postData = data;

    setLoading(true);

    if(isAddMode){
      return axios.post(process.env.REACT_APP_API_URL + 'study-materials', postData,{
        headers: {'x-access-token': auth?.token}
      }).then(response => {
        setLoading(false);
        toast.success(response.data.message);
        navigate('/admin/study-material/list');
      }).catch(error => {
        setLoading(false);
        if(error && error.response && error.response.data && error.response.data.message){
          toast.error(error.response.data.message);
        }else{
          toast.error("Error occured. Please try again");
        }
      });
    }else{
      return axios.put(process.env.REACT_APP_API_URL + 'study-materials/'+id, postData,{
        headers: {'x-access-token': auth?.token}
      }).then(response => {
        setLoading(false);
        toast.success(response.data.message);
        navigate('/admin/study-material/list');
      }).catch(error => {
        setLoading(false);
        if(error && error.response && error.response.data && error.response.data.message){
          toast.error(error.response.data.message);
        }else{
          toast.error("Error occured. Please try again");
        }
      });
    }
  }

  const handleFileChange = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setPreview(null)
      return;
    }
    setLoading(true);

    const postData = new FormData();
    postData.append('file', e.target.files[0]);
    
    axios.post(process.env.REACT_APP_API_URL + 'public/upload-file', postData, {
      headers: { 'x-access-token': auth?.token }
    }).then(response => {
      setLoading(false);
      setPreview(response.data.data.url);
      setValue('file',response.data.data.name);
    }).catch(error => {
      setLoading(false);
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error occured. Please try again");
      }
    });
  };

  return (<React.Fragment>
    {loading && <Loader /> }
    {contentLoading && <div className="LoaderCard"><div className="Loader FromLoader"><FromLoader /></div></div> }
    {!contentLoading && <div className={`${styles.MainDiv}`}>
      <div className={`${styles.PageTitleWrap}`}>
        <p className={`${styles.PageTitle}`}>Study Material {isAddMode?'Add':'Edit'}</p>
        <Breadcrumbs aria-label="breadcrumb" className='breadcrumb'>
          <Link underline="hover" color="inherit" to="/admin/dashboard">Dashboard</Link>
          <Link underline="hover" color="inherit" to={'/admin/study-material/list'}>Study Materials</Link>
          <Typography color="text.primary">{isAddMode?'Add':'Edit'}</Typography>
        </Breadcrumbs>
      </div>
      
      <div className={`${styles.MainCard}`}>
        <div className={`${styles.AddUserMain}`}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroupFull}`}>
                <label className={`${styles.FormLabel}`}>Title*</label>
                <input {...register("title")} className={`${styles.FormControl}`} />
                {errors.title && !watch().title && <span className={`${styles.ErrorM}`}>{errors.title.message}</span>}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroupFull}`}>
                <label className={`${styles.FormLabel}`}>Class*</label>
                <Controller control={control} options={classList} {...register('class_id')} render={({ field }) => (
                  <Select {...field} options={classList} onChange={(e)=> {field.onChange(e); setSelectedData({...selectedData, class: e.value});}} />
                )}/>
                {errors.class_id && !watch().class_id && <span className={`${styles.ErrorM}`}>{errors.class_id.message}</span>}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroupFull}`}>
                <label className={`${styles.FormLabel}`}>Subject*</label>
                <Controller control={control} options={subjectList} {...register('subject_id')} render={({ field }) => (
                  <Select {...field} options={subjectList} />
                )}/>
                {errors.subject_id && !watch().subject_id && <span className={`${styles.ErrorM}`}>{errors.subject_id.message}</span>}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroupFull}`}>
                <label className={`${styles.FormLabel}`}>Upload File*</label>
                <br />
                <input style={{'display':'none'}} id="file" type="file" name="file" accept=".pdf" onChange={handleFileChange} />
                <label htmlFor="file" className={`${styles.SubmitBU}`}>Add File</label>
                <br />
                {preview &&  <img src="/static/img/pdf.svg" style={{'marginTop':'10px','width':'50px'}} alt='' /> }
                <br />
                {errors.file && !watch().file && <span className={`${styles.ErrorM}`}>{errors.file.message}</span>}
              </div>
            </div>
            
            <div className={`${styles.FormButtonArea}`}>
              <Link to={`/admin/study-material/list`} as={Button} className={`${styles.CancelBU}`}>Cancel</Link>
              <input className={`${styles.SubmitBU}`} value="Submit" type="submit" />
            </div>
          </form>
        </div>
      </div>
    </div>}
  </React.Fragment>)
}

export default withAdminAuth(StudyMaterialAdd);